import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../dist/styles/RichTextEditor.min.css';

const RichTextEditor = ({ value, onChange, hideToolbar, onPost, onCancel, onToggleFormatting, onToggleRating, showFormattingOptions }) => {
    const [editorKey, setEditorKey] = useState(0);

    useEffect(() => {
        // Force re-render of the ReactQuill component by changing the key when hideToolbar changes
        setEditorKey((prevKey) => prevKey + 1);
    }, [hideToolbar]);

    const modules = {
        toolbar: hideToolbar ? false : [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ 'align': [] }],
        ],
    };

    return (
        <div className={`rich-text-editor ${hideToolbar ? 'no-toolbar' : ''}`}>
            <ReactQuill
                key={editorKey} // Force re-render by changing key
                value={value}
                onChange={onChange}
                modules={modules}
                theme="snow"
            />
            <div className="action-buttons">
                <div className='seperator-1'>
                    <button
                        className="formatting-toggle-button"
                        onClick={onToggleFormatting}
                    >
                        <i className="fas fa-font"></i>
                    </button>
                    <button
                        className="rating-toggle-button"
                        onClick={onToggleRating}
                    >
                        <i className="fas fa-fire-alt"></i>
                    </button>
                </div>
                <div className='seperator-2'>
                    <button className="cancel-button" onClick={onCancel}>
                        <i className="fas fa-times"></i>
                    </button>
                    <button
                        className="post-button"
                        onClick={onPost}
                        disabled={!value}
                    >
                        <i className="fas fa-arrow-circle-up blue-icon"></i> 
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RichTextEditor;
