// src/components/SignInModal.js

import React, { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';
import SignIn from './SignIn';
import '../../dist/styles/Modal.min.css';
import img1 from '../../assets/greyLogo0.5x.png';


const SignInModal = ({ onClose, onToggleRegister, onToggleResetPassword }) => {
    const { user } = useContext(AuthContext);

    if (user) {
        return null; // User should not see sign-in modal if already authenticated
    }

    return (
        <>
            <div className="site-overlay"></div>
            <div className="modal-overlay" onClick={(e) => handleOverlayClick(e, onClose)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div><img src={img1} alt="Logo" className='logo-image' /></div>
                        <h2>Log In Here</h2>
                        <i className="fas fa-times modal-close-icon" onClick={onClose}></i>
                    </div>
                    <SignIn />
                    <div className="modal-footer">
                        <p>
                            Don't have an account?{' '}
                            <span className="modal-link" onClick={onToggleRegister}>
                                Sign Up Here
                            </span>
                        </p>
                        <p>
                            Forgot your password?{' '}
                            <span className="modal-link" onClick={onToggleResetPassword}>
                                Reset Password Here
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignInModal;

function handleOverlayClick(e, onClose) {
    if (e.target.classList.contains('site-overlay') || e.target.classList.contains('modal-overlay')) {
        onClose();
    }
}