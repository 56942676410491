import React, { useEffect, useRef, useContext, useState } from 'react';
import RichTextEditor from './RichTextEditor';
import { AuthContext } from '../membersarea/contexts/AuthContext';

const NewCommentSection = ({
    commentText, setCommentText, handlePostComment, replyTo, setReplyTo, editorRef,
    parentCommentId, userRating, setUserRating, companyName, className
}) => {
    const { user } = useContext(AuthContext);
    const [isEditing, setIsEditing] = useState(false);
    const [showFormattingOptions, setShowFormattingOptions] = useState(false);
    const [showRatingSection, setShowRatingSection] = useState(false); // Initially hidden

    useEffect(() => {
        if (editorRef.current && isEditing) {
            editorRef.current.getEditor().focus();
        }
    }, [replyTo, isEditing]);

    const handleRatingClick = (rating) => {
        setUserRating(rating);
    };

    const getColorForRating = (rating) => {
        const red = rating <= 5
            ? 239
            : Math.floor(239 + (16 - 239) * (rating - 5) / 5);
        const green = rating <= 5
            ? Math.floor(68 + (158 - 68) * rating / 5)
            : Math.floor(158 + (184 - 158) * (rating - 5) / 5);
        const blue = rating <= 5
            ? 68
            : Math.floor(68 + (129 - 68) * (rating - 5) / 5);
        
        return `rgb(${red},${green},${blue})`;
    };

    const getSelectedColorForRating = (rating) => {
        return '#4d4db2';
    };

    const handlePostCommentWrapper = () => {
        if (!user.is_verified) {
            window.alert('Please verify your email to post a comment.');
            return;
        }
        handlePostComment(parentCommentId, userRating);
        setCommentText('');
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setCommentText('');
    };

    return (
        <div className={`textarea-wrapper ${replyTo ? 'replying' : ''} ${className || ''}`}>
            {!isEditing ? (
                <div
                    className="placeholder-box"
                    onClick={() => setIsEditing(true)}
                    style={{
                        border: '1.5px solid #ccc',
                        padding: '10px',
                        cursor: 'pointer',
                    }}
                >
                    Join in the discussion...
                </div>
            ) : (
                <>
                    <RichTextEditor
                        value={commentText}
                        onChange={setCommentText}
                        ref={editorRef}
                        hideToolbar={!showFormattingOptions}
                        onPost={handlePostCommentWrapper}
                        onCancel={handleCancel}
                        onToggleFormatting={() => setShowFormattingOptions(!showFormattingOptions)}
                        onToggleRating={() => setShowRatingSection(!showRatingSection)}
                        showFormattingOptions={showFormattingOptions}
                    />
                </>
            )}

            {showRatingSection && (
                <div className="rating-header">
                    <h4 className="h-no-margin">Rate its outlook:</h4>

                    <div className="rating-buttons">
                        {[...Array(10).keys()].map((_, index) => {
                            const rating = index + 1;
                            const isSelected = userRating === rating;
                            return (
                                <div
                                    key={rating}
                                    className={`rating-button ${isSelected ? 'selected' : ''}`}
                                    style={{
                                        backgroundColor: getColorForRating(rating),
                                        borderColor: isSelected
                                            ? getSelectedColorForRating(rating)
                                            : getColorForRating(rating),
                                    }}
                                    onClick={() => handleRatingClick(rating)}
                                >
                                    {rating}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewCommentSection;
