import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../dist/styles/ConstituentsGrid.min.css';
import SidePanel from './SidePanel';
import ScrollToTopButton from './ScrollToTopButton';
import axiosInstance from '../config/axiosConfig';
import noDataCiks from '../config/noDataCiks';
import { Link } from 'react-router-dom';
import ComparisonModal from './companyComparisonModal';
import TradingViewMiniWidgetLazy from '../widgets/TradingViewMiniWidgetLazy'; // Lazy widget

const ConstituentsGrid = ({
    constituents: initialConstituents,
    // setUpdateFlag,
    showIndexName,
    isSidePanelOpen,
    setIsSidePanelOpen,
    token,
    setErrorMessage,
    setPopupPosition,
    setShowPopup,
    searchTerm,
    selectedSector
}) => {
    const [constituents, setConstituents] = useState([]);
    const [selectedConstituentIndex, setSelectedConstituentIndex] = useState(null);
    const [expandedConstituentIndex, setExpandedConstituentIndex] = useState(null);
    const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);
    const [selectedConstituentCik, setSelectedConstituentCik] = useState(null);
    const constituentsColumnRef = useRef(null);

    const displayNames = {
        'ftse_100': 'FTSE 100',
        'sp_500': 'S&P 500',
        'dow_jones': 'Dow Jones',
        'nasdaq': 'Nasdaq'
    };

    useEffect(() => {
        setConstituents(initialConstituents);
    }, [initialConstituents]);

    useEffect(() => {
        const filteredConstituents = initialConstituents.filter(constituent => {
            const matchesSearchTerm = constituent.company_name &&
                constituent.company_name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesSector = selectedSector === 'all' ||
                (constituent.gics_sector && constituent.gics_sector === selectedSector);
            return matchesSearchTerm && matchesSector;
        });
        setConstituents(filteredConstituents);
    }, [searchTerm, selectedSector, initialConstituents]);

    const openSidePanel = (constituent, index) => {
        setSelectedConstituentIndex(index);
        setIsSidePanelOpen(true);
    };

    const closeSidePanel = () => {
        setSelectedConstituentIndex(null);
        setIsSidePanelOpen(false);
    };

    const toggleStarColor = async (event, company_id) => {
        event.stopPropagation();

        if (!token) {
            setPopupPosition({ x: event.clientX, y: event.clientY });
            setErrorMessage('Sign in to add to favourites');
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 5000);
            return;
        }

        try {
            const constituent = constituents.find(c => c.company_id === company_id);

            if (!constituent) {
                console.error(`Constituent not found for company ID ${company_id}`);
                return;
            }

            const isCurrentlyFavourite = constituent.isFavourite;

            if (isCurrentlyFavourite) {
                const response = await axiosInstance.delete(`/constituents/favourites/remove/${company_id}`);
                if (response.status === 200) {
                    setConstituents(prev => prev.map(c => c.company_id === company_id ? { ...c, isFavourite: false } : c));
                } else {
                    console.error('Failed to remove favourite:', response.data);
                }
            } else {
                const response = await axiosInstance.post('/constituents/favourites/add', { company_id });
                if (response.status === 200) {
                    setConstituents(prev => prev.map(c => c.company_id === company_id ? { ...c, isFavourite: true } : c));
                } else {
                    console.error('Failed to add favourite:', response.data);
                }
            }

            // setUpdateFlag(prev => !prev);
        } catch (error) {
            console.error('Error toggling favourite status:', error);
        }
    };

    const handlePlusMinusClick = (index) => {
        setExpandedConstituentIndex(prevIndex => prevIndex === index ? null : index);
    };

    const handleExpandedContentClick = (event) => {
        event.stopPropagation();
    };

    const handleIconClick = (cik) => {
        setSelectedConstituentCik(cik);
        setIsComparisonModalOpen(true);
    };

    return (
        <div className="grid-container">
            <div className={`constituents-column`} ref={constituentsColumnRef}>
                {constituents.length === 0 ? (
                    <h1 className="no-constituents-message">No constituents found</h1>
                ) : (
                    <div className="constituents-grid">
                        {constituents.map((constituent, index) => (
                            <ConstituentItem
                                key={index}
                                constituent={constituent}
                                index={index}
                                openSidePanel={openSidePanel}
                                expandedConstituentIndex={expandedConstituentIndex}
                                handlePlusMinusClick={handlePlusMinusClick}
                                handleIconClick={handleIconClick}
                                toggleStarColor={toggleStarColor}
                                token={token}
                                handleExpandedContentClick={handleExpandedContentClick}
                            />
                        ))}
                    </div>
                )}
                {isComparisonModalOpen && (
                    <ComparisonModal
                        isOpen={isComparisonModalOpen}
                        onClose={() => setIsComparisonModalOpen(false)}
                        initialCompanyData={selectedConstituentCik}
                    />
                )}
                <ScrollToTopButton constituentsGridRef={constituentsColumnRef} />
            </div>
            {isSidePanelOpen && selectedConstituentIndex !== null && constituents[selectedConstituentIndex] && (
                <div className={`side-panel-overlay ${isSidePanelOpen ? 'open' : ''}`}>
                    <SidePanel
                        isOpen={isSidePanelOpen}
                        onClose={closeSidePanel}
                        constituent={constituents[selectedConstituentIndex]}
                    />
                </div>
            )}
        </div>
    );
};

const ConstituentItem = ({
    constituent,
    index,
    openSidePanel,
    expandedConstituentIndex,
    handlePlusMinusClick,
    handleIconClick,
    toggleStarColor,
    token,
    handleExpandedContentClick
}) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    const [voteCount, setVoteCount] = useState({ likes: 0, dislikes: 0 });
    const [userVote, setUserVote] = useState(null);

    useEffect(() => {
        fetchVoteCount();
        if (token) {
            fetchUserVote();
        }
    }, [constituent.company_id, token]);

    const fetchVoteCount = async () => {
        try {
            const response = await axiosInstance.get(`/constituents/company/votes/${constituent.company_id}`);
            setVoteCount(response.data);
        } catch (error) {
            console.error('Error fetching vote count:', error);
        }
    };

    const fetchUserVote = async () => {
        try {
            const response = await axiosInstance.get(`/constituents/user/vote/${constituent.company_id}`);
            setUserVote(response.data.vote);
        } catch (error) {
            console.error('Error fetching user vote:', error);
        }
    };

    const handleVote = async (voteType) => {
        if (!token) {
            alert('Please sign in to vote');
            return;
        }

        try {
            let endpoint;
            if (userVote === voteType) {
                endpoint = '/constituents/company/neutralize';
            } else {
                endpoint = voteType === 'like' ? '/constituents/company/like' : '/constituents/company/dislike';
            }

            await axiosInstance.post(endpoint, { company_id: constituent.company_id });
            await fetchVoteCount(); // Fetch updated vote count
            setUserVote(userVote === voteType ? null : voteType);
        } catch (error) {
            console.error('Error voting:', error);
        }
    };

    const calculateOverallVoteStatus = () => {
        const total = voteCount.likes - voteCount.dislikes;
        if (total === 0) return 'neutral';
        return total > 0 ? 'positive' : 'negative';
    };

    return (
        <div ref={ref} className={`constituent ${expandedConstituentIndex === index ? 'expanded' : ''}`}>
            <div className='top-section'>
                <div className="top-row">
                    <div className="company">
                        {constituent.company_name}
                    </div>
                    <div className='display-flex'>
                        <div className={`vote-oval ${calculateOverallVoteStatus()}`}>
                            <div 
                                className={`vote-half positive ${userVote === 'like' ? 'active' : ''}`} 
                                onClick={() => handleVote('like')}
                            >
                                <i className="fas fa-thumbs-up"></i>
                            </div>
                            <div 
                                className={`vote-half negative ${userVote === 'dislike' ? 'active' : ''}`} 
                                onClick={() => handleVote('dislike')}
                            >
                                <i className="fas fa-thumbs-down"></i>
                            </div>
                        </div>
                        <span
                            className={`star-icon ${constituent.isFavourite ? 'star-active' : ''}`}
                            onClick={(event) => toggleStarColor(event, constituent.company_id)}
                        >
                            &#9733;
                        </span>
                        <div>
                            <i
                                className={`fas fa-plus-square plus-button mobile-render`}
                                style={{ display: expandedConstituentIndex === index ? 'none' : 'inline' }}
                                onClick={() => handlePlusMinusClick(index)}
                            ></i>
                            <i
                                className={`fas fa-minus-square plus-button mobile-render`}
                                style={{ display: expandedConstituentIndex === index ? 'inline' : 'none' }}
                                onClick={() => handlePlusMinusClick(index)}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className='middle-section'>
                <div className='chart-section'>
                    {inView && (
                        <TradingViewMiniWidgetLazy
                            symbol={constituent.ticker}
                            width="100%"
                            height={240}
                            colorTheme="light"
                        />
                    )}
                </div>
            </div>
            <div className='bottom-section'>
                <div className="gics-info">
                    <div className='sector-pill industry'>{constituent.gics_sub_industry}</div>
                    <div className='sector-pill sector'>{constituent.gics_sector}</div>
                </div>
                <div className="button-container">
                    <div></div>
                    <div className='constits-buttons'>
                        <div>
                            <button
                                className="view-icon-button view-more-button"
                                onClick={() => handleIconClick(constituent.cik)}
                            >
                                Compare Co.
                            </button>
                        </div>
                        <div>
                            <button className="view-more-button" style={{ marginTop: '5px' }} onClick={() => openSidePanel(constituent, index)}>Expand & Engage</button>
                        </div>
                    </div>
                </div>
            </div>
            {expandedConstituentIndex === index && (
                <div
                    className="expanded-content"
                    style={{ display: 'block' }}
                    onClick={handleExpandedContentClick}
                >
                    <SidePanel
                        isOpen={true}
                        onClose={() => handlePlusMinusClick(index)}
                        constituent={constituent}
                    />
                </div>
            )}
        </div>
    );
};

export default ConstituentsGrid;
