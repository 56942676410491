import React, { useState, useEffect, useRef, useContext } from 'react';
import { 
    fetchCompanyData, 
    fetchFilteredLabelDescription, 
    fetchCoFrames 
} from './sidepanel/SECCIKDataPackage';
import {
    fetchComments,
    applySort,
    handleSortToggle,
    handleSortClick,
    toggleSection,
    showAlert,
    handlePostComment,
    handleReplyClick,
    handleLikeClick,
    handleDislikeClick,
    handleFlagClick,
    handleDeleteClick
} from './sidepanel/sidePanelFunctions';
import Comment from './sidepanel/Comment';
import NewCommentSection from './sidepanel/NewCommentSection';
import { AuthContext } from './membersarea/contexts/AuthContext';
import '../dist/styles/SidePanel.min.css';
import CompanyDataSection from './sidepanel/CompanyDataSection';

const SidePanel = ({ isOpen, onClose, constituent }) => {
    const { user } = useContext(AuthContext);
    const [sort, setSort] = useState('newest');
    const [showSortDropdown, setShowSortDropdown] = useState(false);
    const [comments, setComments] = useState([]);
    const [sortedComments, setSortedComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyTo, setReplyTo] = useState(null);
    const [replyUserRating, setReplyUserRating] = useState(5);
    const [bottomCommentText, setBottomCommentText] = useState('');
    const [bottomUserRating, setBottomUserRating] = useState(5);
    const [collapsedSections, setCollapsedSections] = useState({
        marketStats: false,
        aiSummary: false,
        mediaSentiment: false,
        companyStats: false,
        labelDescription: true, // Default to collapsed
    });
    const [combinedData, setCombinedData] = useState([]);
    const [filteredLabelDescription, setFilteredLabelDescription] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedAccountDescription, setSelectedAccountDescription] = useState('');
    const [coDataMap, setCoDataMap] = useState([]);
    const [coFrames, setCoFrames] = useState([]);
    const sortDropdownRef = useRef();
    const sidePanelRef = useRef(); // Ref for the side panel
    const editorRef = useRef(null);
    const bottomEditorRef = useRef(null);

    useEffect(() => {
        if (isOpen && constituent && constituent.company_id) {
            fetchComments(constituent, setComments, setSortedComments, sort);
            fetchCompanyData(constituent.cik, user ? user.user_id : null, setCombinedData, setSelectedAccount);
            fetchFilteredLabelDescription(constituent.cik, setFilteredLabelDescription);
            fetchCoFrames(constituent.cik, setCoFrames);
        }
    }, [isOpen, constituent]);

    useEffect(() => {
        if (comments.length > 0) {
            applySort(comments, sort, setSortedComments);
        }
    }, [sort]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidePanelRef.current && !sidePanelRef.current.contains(event.target) &&
                sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
                onClose(); // Close the side panel when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return isOpen ? (
        <div className="side-panel open" ref={sidePanelRef}>
            <div className="side-panel-header">
                <h2 className="panel-title">
                    {constituent?.company_name || 'Company Name'} ({constituent?.ticker || 'Ticker'})
                </h2>
                <button className="close-button" onClick={onClose}>
                    <i className="fas fa-times blue-icon"></i>
                </button>
            </div>
            <div className="side-panel-content">
                <div className="collapsible-section">
                    <div className="section-header comments-section-header" onClick={() => toggleSection('companyStats', setCollapsedSections)}>
                        <div className='collapsible-leftside'>
                            <i className={`fas ${collapsedSections.companyStats ? 'fa-chevron-right' : 'fa-chevron-down'}`}></i>
                            <h3 className='h-no-margin'>Company Data</h3>
                        </div>
                    </div>
                    {!collapsedSections.companyStats && (
                        <CompanyDataSection
                            combinedData={combinedData}
                            setCombinedData={setCombinedData}
                            filteredLabelDescription={filteredLabelDescription}
                            selectedAccount={selectedAccount}
                            setSelectedAccount={setSelectedAccount}
                            selectedAccountDescription={selectedAccountDescription}
                            setSelectedAccountDescription={setSelectedAccountDescription}
                            collapsedSections={collapsedSections}
                            setCollapsedSections={setCollapsedSections}
                            toggleSection={toggleSection}
                            constituent={constituent}
                            coDataMap={coDataMap}
                            coFrames={coFrames}
                        />
                    )}
                </div>

                <div className="collapsible-section">
                    <div className="section-header comments-section-header" onClick={() => toggleSection('mediaSentiment', setCollapsedSections)}>
                        <div className='collapsible-leftside'>
                            <i className={`fas ${collapsedSections.mediaSentiment ? 'fa-chevron-right' : 'fa-chevron-down'}`}></i>
                            <h3 className='h-no-margin'>User Comments</h3>
                        </div>
                        <div className="filter-sort-section">
                            <div className="sort-container" ref={sortDropdownRef}>
                                <div className='flex'>
                                    <h3 className='h-no-margin align-self-center' onClick={(e) => handleSortToggle(e, showSortDropdown, setShowSortDropdown)}>Sort data</h3>
                                    <i className="fas fa-sort blue-icon" title="Sort By" onClick={(e) => handleSortToggle(e, showSortDropdown, setShowSortDropdown)}></i>
                                    <div className={`sort-dropdown ${showSortDropdown ? 'show' : ''}`}>
                                        <ul onClick={(e) => e.stopPropagation()}>
                                            <li className={sort === 'popular' ? 'active' : ''} onClick={() => handleSortClick('popular', setSort, setShowSortDropdown, applySort, comments, setSortedComments)}>Most Popular</li>
                                            <li className={sort === 'newest' ? 'active' : ''} onClick={() => handleSortClick('newest', setSort, setShowSortDropdown, applySort, comments, setSortedComments)}>Newest</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!collapsedSections.mediaSentiment && (
                        <div className="comments-news-section">
                            {sortedComments.map(comment => (
                                <Comment
                                    key={comment.comment_id}
                                    comment={comment}
                                    handleLikeClick={() => handleLikeClick(user, comment.comment_id, showAlert, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                                    handleDislikeClick={() => handleDislikeClick(user, comment.comment_id, showAlert, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                                    handleReplyClick={() => handleReplyClick(user, comment, setReplyTo, showAlert, editorRef)}
                                    handleFlagClick={() => handleFlagClick(user, comment.comment_id, showAlert, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                                    handleDeleteClick={() => handleDeleteClick(user, comment.comment_id, showAlert, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                                    user_id={user ? user.user_id : null}
                                    fetchData={() => fetchComments(constituent, setComments, setSortedComments, sort)}
                                    replyTo={replyTo}
                                    setReplyTo={setReplyTo}
                                    commentText={commentText}
                                    setCommentText={setCommentText}
                                    editorRef={editorRef}
                                    handlePostComment={() => handlePostComment(user, comment.comment_id, replyUserRating, commentText, bottomCommentText, editorRef, bottomEditorRef, setCommentText, setBottomCommentText, setReplyTo, setReplyUserRating, setBottomUserRating, constituent, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                                    userRating={replyUserRating}
                                    setUserRating={setReplyUserRating}
                                    companyName={constituent?.company_name || 'Company Name'}
                                />
                            ))}
                                                {user ? (
                        <>
                          
                        </>
                    ) : (
                        <h3 className='h-no-margin'>Join the discussion: log in to post comments</h3>
                    )}
                        </div>
                    )}
         
                </div>
                <div className="fixed-bottom">
                    {/* <h3 className='h-no-margin'> Join the discussion: </h3> */}
                    <NewCommentSection
                        className='new-comment-box'
                        commentText={bottomCommentText}
                        setCommentText={setBottomCommentText}
                        handlePostComment={() => handlePostComment(user, null, bottomUserRating, commentText, bottomCommentText, editorRef, bottomEditorRef, setCommentText, setBottomCommentText, setReplyTo, setReplyUserRating, setBottomUserRating, constituent, () => fetchComments(constituent, setComments, setSortedComments, sort))}
                        replyTo={null}
                        setReplyTo={null}
                        editorRef={bottomEditorRef}
                        userRating={bottomUserRating}
                        setUserRating={setBottomUserRating}
                        companyName={constituent?.company_name || 'Company Name'}
                    />
                </div>
            </div>
        </div>
    ) : null;
};

export default SidePanel;
