import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../config/axiosConfig'; // Import your configured axios instance
import { useNavigate } from 'react-router-dom';
import '../../dist/styles/Register.min.css';
import { AuthContext } from '../membersarea/contexts/AuthContext'; // Import AuthContext

const Register = ({ onClose }) => { // Accept onClose prop
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // New loading state
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [passwordRequirementsError, setPasswordRequirementsError] = useState('');
    const [buttonClass, setButtonClass] = useState('disabled');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
    const navigate = useNavigate();
    const { signIn } = useContext(AuthContext); // Use signIn from AuthContext

    useEffect(() => {
        validateConfirmPassword(confirmPassword);
    }, [password, confirmPassword]);

    useEffect(() => {
        if (
            username &&
            email &&
            confirmEmail &&
            password &&
            confirmPassword &&
            termsAgreed &&
            disclaimerAgreed &&
            !passwordRequirementsError &&
            !error
        ) {
            setButtonClass('enabled');
        } else {
            setButtonClass('disabled');
        }
    }, [
        username,
        email,
        confirmEmail,
        password,
        confirmPassword,
        termsAgreed,
        disclaimerAgreed,
        passwordRequirementsError,
        error,
    ]);

    const validatePassword = (password) => {
        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return {
            minLength,
            hasUpperCase,
            hasLowerCase,
            hasNumber,
            hasSpecialChar,
        };
    };

    const validateConfirmPassword = (confirmPassword) => {
        return password === confirmPassword;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);

        const { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = validatePassword(newPassword);

        let errorMessages = [];

        if (!minLength) {
            errorMessages.push('At least 8 characters');
        }
        if (!hasUpperCase) {
            errorMessages.push('One uppercase letter');
        }
        if (!hasLowerCase) {
            errorMessages.push('One lowercase letter');
        }
        if (!hasNumber) {
            errorMessages.push('One number');
        }
        if (!hasSpecialChar) {
            errorMessages.push('One special character');
        }

        if (confirmPassword && newPassword !== confirmPassword) {
            setError('Passwords do not match.');
        } else if (errorMessages.length > 0) {
            setPasswordRequirementsError(`Password must include: ${errorMessages.join(', ')}`);
            setError('');
        } else {
            setPasswordRequirementsError('');
            setError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (!validateConfirmPassword(e.target.value)) {
            setError('Passwords do not match.');
        } else {
            setError('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        if (!termsAgreed || !disclaimerAgreed) {
            setError('Please agree to both Terms of Use and Disclaimer.');
            return;
        }

        const { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = validatePassword(password);

        let errorMessages = [];

        if (!minLength) {
            errorMessages.push('At least 8 characters');
        }
        if (!hasUpperCase) {
            errorMessages.push('One uppercase letter');
        }
        if (!hasLowerCase) {
            errorMessages.push('One lowercase letter');
        }
        if (!hasNumber) {
            errorMessages.push('One number');
        }
        if (!hasSpecialChar) {
            errorMessages.push('One special character');
        }

        if (confirmPassword !== password) {
            setError('Passwords do not match.');
        } else if (errorMessages.length > 0) {
            setPasswordRequirementsError(`Password must include: ${errorMessages.join(', ')}`);
            setError('');
            return;
        } else {
            setPasswordRequirementsError('');
            setError('');
        }

        if (email !== confirmEmail) {
            setError('Emails do not match.');
            return;
        }

        try {
            const response = await axiosInstance.post('/users/signup', { username, email, password });
            setMessage(response.data.message);
            setShowSuccessMessage(true); // Show success message prompt

            // Sign in the user
            const userData = {
                ...response.data.user, // User data from the backend
                token: response.data.token, // Token from the backend
            };
            signIn(userData); // Update context and local storage

            setError('');
            setUsername('');
            setEmail('');
            setConfirmEmail('');
            setPassword('');
            setConfirmPassword('');
            setTermsAgreed(false);
            setDisclaimerAgreed(false);
            setButtonClass('disabled');

            // Close the modal and refresh the page
            onClose();
            window.location.reload();

            // Redirect to home page
            navigate('/');

        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.error);
            } else {
                setError('Something went wrong. Please try again.');
            }
        } finally {
            setLoading(false); // Stop loading
        }
    }; 

    return (
        <div>
            {showSuccessMessage && (
                <div className="success-message">
                    <p>Registration successful! You can now log in.</p>
                    <button onClick={() => setShowSuccessMessage(false)}>Close</button>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username *"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Confirm Email *"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                />
                <div className="input-container">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password *"
                        value={password}
                        onChange={handlePasswordChange}
                        onPaste={handlePasswordChange}
                        required
                    />
                    <i
                        className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}
                        onClick={togglePasswordVisibility}
                    ></i>
                </div>
                <div className="input-container">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Confirm Password *"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                    <i
                        className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}
                        onClick={togglePasswordVisibility}
                    ></i>
                </div>
                {passwordRequirementsError && <p className="error">{passwordRequirementsError}</p>}
                {error && <p className="error">{error}</p>}
                <div className="checkbox-section">
                    <input
                        type="checkbox"
                        id="terms-of-use"
                        name="terms-of-use"
                        checked={termsAgreed}
                        onChange={(e) => setTermsAgreed(e.target.checked)}
                        required
                    />
                    <label htmlFor="terms-of-use">I agree to the Terms of Use</label>
                </div>
                <div className="checkbox-section">
                    <input
                        type="checkbox"
                        id="disclaimer"
                        name="disclaimer"
                        checked={disclaimerAgreed}
                        onChange={(e) => setDisclaimerAgreed(e.target.checked)}
                        required
                    />
                    <label htmlFor="disclaimer">
                        I understand this is not financial advice and all investments are made at my own risk
                    </label>
                </div>
                <button type="submit" disabled={loading}>
                {loading ? (
                    <>
                        <i className="fas fa-spinner fa-spin"></i> Registering...
                    </>
                ) : (
                    'Sign Up'
                )}
            </button>
                {message && <p className="success">{message}</p>}
            </form>
        </div>
    );
};

export default Register;
