import React from 'react';
import Register from './Register'; // Import the Register component
import '../../dist/styles/Modal.min.css';
import img1 from '../../assets/greyLogo0.5x.png';

const RegisterModal = ({ onClose, onToggleSignIn }) => {
    return (
        <>
            <div className="site-overlay"></div>
            <div className="modal-overlay" onClick={(e) => handleOverlayClick(e, onClose)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div><img src={img1} alt="Logo" className='logo-image' /></div>
                        <h2>Sign Up Here</h2>
                        <i className="fas fa-times modal-close-icon" onClick={onClose}></i>
                    </div>
                    <Register /> {/* Render the Register component */}
                    <div className="modal-footer">
                        <p>
                            Already have an account?{' '}
                            <span className="modal-link" onClick={onToggleSignIn}>
                                Log In Here
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterModal;

function handleOverlayClick(e, onClose) {
    if (e.target.classList.contains('site-overlay') || e.target.classList.contains('modal-overlay')) {
        onClose();
    }
}
