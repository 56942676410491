import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../dist/styles/Privacy.min.css';

const Privacy = () => {
    return (
        <div>
            <Header />
            <div className="content">
                <h1 className="fixed-title">Terms of Use and Privacy Policy</h1>
                <div className="main-content">
                    <h2>1. Introduction</h2>
                    <p>Welcome to OpenEquity ("we", "us", "our"). By accessing or using our website located at <a href="http://www.opneqty.com" target="_blank" rel="noopener noreferrer">www.opneqty.com</a> (the "Site"), you agree to comply with and be bound by these Terms of Use ("Terms"). Please read them carefully.</p>

                    <h2>2. Acceptance of Terms</h2>
                    <p>By using OpenEquity, you agree to these Terms. If you do not agree with any part of these Terms, you must not use the Site.</p>

                    <h2>3. Changes to Terms</h2>
                    <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Site after any changes constitutes your acceptance of the new Terms.</p>

                    <h2>4. Use of the Site</h2>
                    <p>You agree to use OpenEquity only for lawful purposes and in accordance with these Terms. You agree not to:
                        <ul>
                            <li>Use the Site in any way that violates any applicable local, national, or international law or regulation.</li>
                            <li>Engage in any activity that is harmful, fraudulent, or deceptive.</li>
                            <li>Transmit any unsolicited or unauthorized advertising, promotional materials, or any other form of solicitation.</li>
                        </ul>
                    </p>

                    <h2>5. User Accounts</h2>
                    <p>To access certain features of the Site, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and password. You agree to notify us immediately of any unauthorized use of your account.</p>

                    <h2>6. User Content</h2>
                    <p>By submitting content to OpenEquity, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display your content. You agree not to submit content that:
                        <ul>
                            <li>Is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.</li>
                            <li>Infringes on the intellectual property rights of any third party.</li>
                        </ul>
                    </p>

                    <h2>7. User Conduct</h2>
                    <p>When participating in discussions, posting comments, or submitting content on OpenEquity, we expect you to:
                        <ul>
                            <li>Be respectful and courteous to others.</li>
                            <li>Engage in constructive and meaningful discussions.</li>
                            <li>Avoid using offensive, abusive, or discriminatory language.</li>
                            <li>Do not harass, intimidate, or threaten other users.</li>
                            <li>Refrain from posting or sharing content that is misleading, harmful, or illegal.</li>
                        </ul>
                    </p>
                    <p>We reserve the right to remove any content or suspend user accounts that do not comply with these guidelines, at our sole discretion.</p>

                    <h2>8. Intellectual Property</h2>
                    <p>All content on the Site, including text, graphics, logos, and software, is the property of OpenEquity or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from the Site without our express written permission.</p>

                    <h2>9. Disclaimers and Limitation of Liability</h2>
                    <p>The information on OpenEquity is provided "as is" and "as available" without any warranties of any kind. We do not warrant the accuracy, completeness, or usefulness of this information. To the fullest extent permitted by law, we disclaim all warranties, express or implied.</p>
                    <p><strong>No Investment Advice:</strong> The content provided on OpenEquity is for informational purposes only and does not constitute investment advice, financial guidance, or a recommendation to buy or sell any securities. Users should not rely on the information provided on this Site to make any investment decisions. Always consult with a qualified financial advisor before making any investment decisions.</p>
                    <p>OpenEquity will not be liable for any damages of any kind arising from the use of the Site, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

                    <h2>10. Privacy Policy</h2>
                    <p>Your use of the Site is also governed by our Privacy Policy. Please review our Privacy Policy for information on how we collect, use, and disclose your personal information.</p>

                    <h2>11. Cookies</h2>
                    <p>Our website uses cookies to enhance your browsing experience and provide personalized content. Cookies are small data files that are placed on your device when you visit our site. They help us understand how you use our site, remember your preferences, and improve your overall experience.</p>
                    <p><strong>Types of Cookies We Use:</strong></p>
                    <ul>
                        <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly and enable you to use its features.</li>
                        <li><strong>Performance Cookies:</strong> These cookies help us analyze how visitors use our site, which pages they visit, and how they navigate the site. This helps us improve site performance.</li>
                        <li><strong>Functional Cookies:</strong> These cookies allow the site to remember your preferences, such as language or region, to provide a more personalized experience.</li>
                        <li><strong>Targeting Cookies:</strong> These cookies are used to deliver advertisements relevant to you and your interests.</li>
                    </ul>
                    <p><strong>Managing Cookies:</strong> You can manage or disable cookies through your browser settings. However, please note that disabling cookies may affect the functionality of our website. For more information on how to manage cookies, please visit <a href="https://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noopener noreferrer">AllAboutCookies.org</a>.</p>

                    <h2>12. Termination</h2>
                    <p>We reserve the right to terminate or suspend your account and access to the Site at our sole discretion, without notice and for any reason, including if you violate these Terms.</p>

                    <h2>13. Governing Law</h2>
                    <p>These Terms are governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in England and Wales.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Privacy;
