import React, { useState, useEffect, useRef, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { fetchCompanyData, fetchFilteredLabelDescription, fetchCoFrames, fetchModelCOA } from './SECCIKDataPackage';
import GraphicalView from './GraphicalView';
import FinancialStatementsView from './FinancialStatementsView';
import CommunityDataMapping from './CommunityDataMapping';

const CompanyDataSection = ({
    combinedData,
    setCombinedData,
    filteredLabelDescription,
    selectedAccount,
    setSelectedAccount,
    selectedAccountDescription,
    setSelectedAccountDescription,
    collapsedSections,
    setCollapsedSections,
    toggleSection,
    constituent,
    coDataMap,
    coFrames
}) => {
    const [breakdownMethod, setBreakdownMethod] = useState('raw');
    const [viewMethod, setViewMethod] = useState('graphical');
    const [financialStatementTab, setFinancialStatementTab] = useState('profitLoss');
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [displayUnit, setDisplayUnit] = useState(1000000);
    const [decimalPlaces, setDecimalPlaces] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    const [modelCOAData, setModelCOAData] = useState({ financialStatements: [], highLevelCategories: {}, detailedCategories: {} });
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);  
    const [openDropdown, setOpenDropdown] = useState(null);
    const filterRef = useRef(null);

    const financialStatementCategories = {
        profitLoss: 'P&L Statement',
        balanceSheet: 'Balance Sheet',
        cashFlow: 'Cash Flow Statement'
    };

    const availableUnits = useMemo(() => {
        const unitSet = new Set();
        combinedData.forEach(data => {
            if (data.unit) {
                unitSet.add(data.unit);
            }
        });
        return Array.from(unitSet);
    }, [combinedData]);



    const toggleDropdown = (dropdownName) => {
        setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
    };
    
    useEffect(() => {
        if (combinedData.length > 0) {
            const years = [...new Set(combinedData.map(data => data.end))].sort();
            setStartYear(years[0]);
            setEndYear(years[years.length - 1]);
            setLoading(false);
            setError(null);  // Stop loading when data is ready
        } else {
            setLoading(true);
             setError(null);
        }
    }, [combinedData]);

    useEffect(() => {
        if (availableUnits.length > 0 && !selectedUnit) {
            setSelectedUnit(availableUnits[0]);
        }
    }, [availableUnits, selectedUnit]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterRef]);

    useEffect(() => {
        const fetchCOAData = async () => {
            try {
                const data = await fetchModelCOA();
                setModelCOAData(data);
            } catch (error) {
                console.error('Error fetching model COA data:', error);
                setError('Currently, there is no data available for this company.'); // Set error message
            }
        };

        fetchCOAData();
    }, []);

    const handleUnitChange = (event) => {
        setSelectedUnit(event.target.value);
    };

    const handleDisplayUnitChange = (event) => {
        setDisplayUnit(Number(event.target.value));
    };

    const handleDecimalPlacesChange = (event) => {
        setDecimalPlaces(Number(event.target.value));
    };

    const handleStartYearChange = (event) => {
        setStartYear(event.target.value);
    };

    const handleEndYearChange = (event) => {
        setEndYear(event.target.value);
    };

    const formatDate = (date) => format(parseISO(date), 'MMM-yy');

    const noDataAvailable = !loading && combinedData.length === 0;

    return (
        <>
            {loading ? (
               <div className="loading-spinner">
               <i className="fas fa-spinner fa-spin"></i> {/* Example of a loading spinner */}
                </div>
            ) : error ? (
                <h4 className='no-data-message'>
                    {error}
                </h4>
            ) : noDataAvailable ? (
                <h4 className='no-data-message'>
                    Currently, there is no data available for this company.
                </h4>
            ) : (
                <>
                    <div className='market-stats'>
                        <div className='text-ca'>
                        <div className="tabs" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='mobile-tab-holder'>
                                <button
                                    className={`tab ${viewMethod === 'graphical' ? 'active' : ''}`}
                                    onClick={() => setViewMethod('graphical')}
                                >
                                    Graphical
                                </button>
                                <button
                                    className={`tab ${viewMethod === 'financialStatements' ? 'active' : ''}`}
                                    onClick={() => setViewMethod('financialStatements')}
                                >
                                    Financial Statements
                                </button>
                                <button
                                    className={`tab ${viewMethod === 'communityData' ? 'active' : ''}`}
                                    onClick={() => setViewMethod('communityData')}
                                >
                                    Community Mapping
                                </button>
                            </div> 
                            <div className="filter-container tab" ref={filterRef}>
    <div 
        className="filter-toggle flex"
        onClick={() => setFilterOpen(!filterOpen)}
    >
        <i className="fas fa-sliders-h"></i>
        <span style={{margin: '0 10px'}}>Filters</span>
        <i className={`margin-left-10 fas fa-chevron-${filterOpen ? 'up' : 'down'}`}></i>
    </div>
    {filterOpen && (
        <div className="filter-dropdown-container">
            <div className='select-row'>
                <label htmlFor="startYearSelect"><strong>From:&nbsp;</strong></label>
                <div className="custom-select-container menu-filter">
                    <div className="custom-select-header" onClick={() => toggleDropdown('fromYear')}>
                        <span>{formatDate(startYear)}</span>
                        <i className={`fas fa-chevron-${openDropdown === 'fromYear' ? 'up' : 'down'}`}></i>
                    </div>
                    {openDropdown === 'fromYear' && (
                        <div className="custom-select-options">
                            {[...new Set(combinedData.map(data => data.end))].sort().map((year, index) => (
                                <div
                                    key={index}
                                    className={`custom-select-option ${startYear === year ? 'selected' : ''}`}
                                    onClick={() => {
                                        handleStartYearChange({ target: { value: year } });
                                        setOpenDropdown(null);
                                    }}
                                >
                                    {formatDate(year)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className='select-row'>
                <label htmlFor="endYearSelect"><strong>To:&nbsp;</strong></label>
                <div className="custom-select-container menu-filter">
                    <div className="custom-select-header" onClick={() => toggleDropdown('toYear')}>
                        <span>{formatDate(endYear)}</span>
                        <i className={`margin-left-10 fas fa-chevron-${openDropdown === 'toYear' ? 'up' : 'down'}`}></i>
                    </div>
                    {openDropdown === 'toYear' && (
                        <div className="custom-select-options">
                            {[...new Set(combinedData.map(data => data.end))].sort().map((year, index) => (
                                <div
                                    key={index}
                                    className={`custom-select-option ${endYear === year ? 'selected' : ''}`}
                                    onClick={() => {
                                        handleEndYearChange({ target: { value: year } });
                                        setOpenDropdown(null);
                                    }}
                                >
                                    {formatDate(year)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className='select-row'>
                <label htmlFor="unitSelect"><strong>Currency:&nbsp;</strong></label>
                <div className="custom-select-container menu-filter">
                    <div className="custom-select-header" onClick={() => toggleDropdown('currency')}>
                        <span>{selectedUnit}</span>
                        <i className={`margin-left-10 fas fa-chevron-${openDropdown === 'currency' ? 'up' : 'down'}`}></i>
                    </div>
                    {openDropdown === 'currency' && (
                        <div className="custom-select-options">
                            {availableUnits.map((unit, index) => (
                                <div
                                    key={index}
                                    className={`custom-select-option ${selectedUnit === unit ? 'selected' : ''}`}
                                    onClick={() => {
                                        handleUnitChange({ target: { value: unit } });
                                        setOpenDropdown(null);
                                    }}
                                >
                                    {unit}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className='select-row'>
                <label htmlFor="displayUnitSelect"><strong>Display Unit:&nbsp;</strong></label>
                <div className="custom-select-container menu-filter">
                    <div className="custom-select-header" onClick={() => toggleDropdown('displayUnit')}>
                        <span>{displayUnit === 1 ? 'Units' : displayUnit === 1000 ? 'Thousands' : displayUnit === 1000000 ? 'Millions' : 'Billions'}</span>
                        <i className={`margin-left-10 fas fa-chevron-${openDropdown === 'displayUnit' ? 'up' : 'down'}`}></i>
                    </div>
                    {openDropdown === 'displayUnit' && (
                        <div className="custom-select-options">
                            <div className={`custom-select-option ${displayUnit === 1 ? 'selected' : ''}`} onClick={() => { handleDisplayUnitChange({ target: { value: 1 } }); setOpenDropdown(null); }}>Units</div>
                            <div className={`custom-select-option ${displayUnit === 1000 ? 'selected' : ''}`} onClick={() => { handleDisplayUnitChange({ target: { value: 1000 } }); setOpenDropdown(null); }}>Thousands</div>
                            <div className={`custom-select-option ${displayUnit === 1000000 ? 'selected' : ''}`} onClick={() => { handleDisplayUnitChange({ target: { value: 1000000 } }); setOpenDropdown(null); }}>Millions</div>
                            <div className={`custom-select-option ${displayUnit === 1000000000 ? 'selected' : ''}`} onClick={() => { handleDisplayUnitChange({ target: { value: 1000000000 } }); setOpenDropdown(null); }}>Billions</div>
                        </div>
                    )}
                </div>
            </div>

            <div className='select-row'>
                <label htmlFor="decimalPlacesSelect"><strong>Decimals:&nbsp;</strong></label>
                <div className="custom-select-container menu-filter">
                    <div className="custom-select-header" onClick={() => toggleDropdown('decimals')}>
                        <span>{decimalPlaces}</span>
                        <i className={`margin-left-10 fas fa-chevron-${openDropdown === 'decimals' ? 'up' : 'down'}`}></i>
                    </div>
                    {openDropdown === 'decimals' && (
                        <div className="custom-select-options">
                            {[0, 1, 2, 3].map((value) => (
                                <div
                                    key={value}
                                    className={`custom-select-option ${decimalPlaces === value ? 'selected' : ''}`}
                                    onClick={() => {
                                        handleDecimalPlacesChange({ target: { value } });
                                        setOpenDropdown(null);
                                    }}
                                >
                                    {value}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )}
</div>
                        </div>
                        </div>

                        <div className="co-content-section tab-content">
                            {viewMethod === 'graphical' && (
                                <GraphicalView
                                    combinedData={combinedData}
                                    filteredLabelDescription={filteredLabelDescription}
                                    modelCOAData={modelCOAData}
                                    breakdownMethod={breakdownMethod}
                                    setBreakdownMethod={setBreakdownMethod}
                                    selectedAccounts={selectedAccounts}
                                    setSelectedAccounts={setSelectedAccounts}
                                    selectedAccountDescription={selectedAccountDescription}
                                    setSelectedAccountDescription={setSelectedAccountDescription}
                                    toggleSection={toggleSection}
                                    setCollapsedSections={setCollapsedSections}
                                    collapsedSections={collapsedSections}
                                    startYear={startYear}
                                    endYear={endYear}
                                    selectedUnit={selectedUnit}
                                    displayUnit={displayUnit}
                                    constituent={constituent}
                                    decimalPlaces={decimalPlaces}
                                    setViewMethod={setViewMethod}
                                />
                            )}
                            {viewMethod === 'financialStatements' && (
                                <FinancialStatementsView
                                    combinedData={combinedData}
                                    financialStatementTab={financialStatementTab}
                                    setFinancialStatementTab={setFinancialStatementTab}
                                    financialStatementCategories={financialStatementCategories}
                                    selectedUnit={selectedUnit}
                                    startYear={startYear}
                                    endYear={endYear}
                                    displayUnit={displayUnit}
                                    constituent={constituent}
                                    decimalPlaces={decimalPlaces}
                                    setViewMethod={setViewMethod}
                                />
                            )}
                            {viewMethod === 'communityData' && (
                                <CommunityDataMapping 
                                    combinedData={combinedData}
                                    setCombinedData={setCombinedData}
                                    setSelectedAccount={setSelectedAccount}
                                    constituent={constituent}
                                    modelCOAData={modelCOAData}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CompanyDataSection;
