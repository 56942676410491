import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/greyLogo0.5x.png';
import '../dist/styles/Header.min.css';
import SignInModal from './membersarea/SignInModal';
import RegisterModal from './membersarea/RegisterModal';
import ResetPasswordModal from './membersarea/ResetPasswordModal';
import UserAreaModal from './membersarea/UserAreaModal';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './membersarea/contexts/AuthContext';

const Header = () => {
    const { user, signOut } = useContext(AuthContext);
    const [isSignInOpen, setIsSignInOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
    const [isMemberAreaOpen, setIsMemberAreaOpen] = useState(false);
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(false);

    const updateView = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        updateView();
        window.addEventListener('resize', updateView);
        return () => window.removeEventListener('resize', updateView);
    }, []);

    const toggleRegister = () => {
        setIsRegisterOpen(!isRegisterOpen);
        setIsSignInOpen(false);
        setIsResetPasswordOpen(false);
    };

    const toggleResetPassword = () => {
        setIsResetPasswordOpen(!isResetPasswordOpen);
        setIsSignInOpen(false);
        setIsRegisterOpen(false);
    };

    const closeModals = () => {
        setIsSignInOpen(false);
        setIsRegisterOpen(false);
        setIsResetPasswordOpen(false);
        setIsMemberAreaOpen(false);
    };

    const toggleSignInModal = () => {
        setIsSignInOpen(true);
        setIsRegisterOpen(false);
        setIsResetPasswordOpen(false);
    };

    const toggleMemberAreaModal = () => {
        setIsMemberAreaOpen(!isMemberAreaOpen);
    };

    return (
        <div className="header">
            {!isMobile && (
                <div className="header-content">
                    <div className="left-section">
                        <div className='site-logo'>
                            <Link to="/">
                                <img src={img1} alt="Logo" className='' />
                            </Link>                    
                        </div>
                        <Link to="/" className='no-link-format'>
                            <h1 className="header-title">OpenEquity</h1>
                        </Link>  
                    </div>
                    <div className="right-section">
                        {user ? (
                            <div>
                                <button style={{backgroundColor: 'white'}} className="member-button" onClick={toggleMemberAreaModal}>
                                    <i className="fas fa-user scale-125"></i>
                                </button>
                            </div>
                        ) : (
                            <button className="header-button" onClick={toggleSignInModal}>
                                Sign In
                            </button>
                        )}
                    </div>
                </div>
            )}
             {isMobile && (
                <div className="header-mobile-content">
                    <div className="mobile-logo">
                        <Link to="/">
                            <img src={img1} alt="Logo" className='scale-80' />
                        </Link>  
                        <div className="mobile-title">OpenEquity</div>                  
                    </div>
                    <div className="mobile-user">
                        {user ? (
                            <button className="member-button" onClick={toggleMemberAreaModal}>
                                <i className="fas fa-user scale-125"></i>
                            </button>
                        ) : (
                            <button className="header-button" onClick={toggleSignInModal}>
                                Sign In
                            </button>
                        )}
                    </div>
                </div>
            )}
            {isSignInOpen && (
                <SignInModal onClose={closeModals} onToggleRegister={toggleRegister} onToggleResetPassword={toggleResetPassword} />
            )}
            {isRegisterOpen && (
                <RegisterModal onClose={closeModals} onToggleSignIn={toggleSignInModal} />
            )}
            {isResetPasswordOpen && (
                <ResetPasswordModal onClose={closeModals} onToggleSignIn={toggleSignInModal}/>
            )}
            {isMemberAreaOpen && (
                <UserAreaModal onClose={closeModals}/>
            )}
        </div>
    );
};

export default Header;
