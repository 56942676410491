import React from 'react';
import '../dist/styles/Footer.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            {/* Desktop Footers */}
            <footer className="footer primary-footer desktop">
                <div className="footer-content">
                    <p>&copy; {new Date().getFullYear()} OpenEquity. All rights reserved. &nbsp;</p> 
                    <p>
                        <Link to="/privacy">Terms of Use</Link>
                    </p>
                </div>
            </footer>
            <footer className="footer secondary-footer desktop">
                <div className="footer-content">
                    <p className="disclaimer">
                        This site is used at your own risk, numbers may not be correct and it is not financial advice. All investments should be based on your own due diligence.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
