import React, { useEffect, useState, useContext, useCallback } from 'react';
import Header from './components/Header';
import CookieBanner from './components/CookieBanner';
import IndexList from './components/IndexList';
import ConstituentsGrid from './components/ConstituentsGrid';
import VerifyEmailPage from './components/VerifyEmailPage';
import ResetPasswordPage from './components/membersarea/ResetPasswordPage';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import { AuthProvider, AuthContext } from './components/membersarea/contexts/AuthContext';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './config/axiosConfig';
import ErrorBoundary from './components/ErrorBoundary';
import { debounce } from 'lodash';

const App = () => {
    const displayNames = {
        'ftse_100': 'FTSE 100',
        'sp_500': 'S&P 500',
        'dow_jones': 'Dow Jones',
        'nasdaq': 'Nasdaq',
        'favourite': 'Portfolio'
    };

    const [indices, setIndices] = useState([
        { name: 'S&P 500', api_name: 'sp_500', requiresAuth: false, constituents: [] },
        { name: 'Dow Jones', api_name: 'dow_jones', requiresAuth: false, constituents: [] },
        { name: 'Nasdaq', api_name: 'nasdaq', requiresAuth: false, constituents: [] },
        { name: 'FTSE 100', api_name: 'ftse_100', requiresAuth: false, constituents: [] },
        { name: 'Portfolio', api_name: 'protected/favourite', requiresAuth: true, constituents: [] }
    ]);

    const [selectedConstituents, setSelectedConstituents] = useState([]);
    const [selectedIndexName, setSelectedIndexName] = useState('');
    const [token, setToken] = useState(null);
    // const [updateFlag, setUpdateFlag] = useState(false);
    const [showIndexName, setShowIndexName] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSector, setSelectedSector] = useState('all');
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [showPopup, setShowPopup] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    const { user } = useContext(AuthContext);

    const fetchToken = () => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            try {
                setToken(storedToken);
            } catch (error) {
                console.error('Invalid token:', error);
                setToken(null);
            }
        } else {
            console.warn('No JWT token found in localStorage');
            setToken(null);
        }
    };

    useEffect(() => {
        fetchToken();
    }, [user]);

    useEffect(() => {
        fetchIndices();
    }, [token, selectedIndexName, selectedSector]);
    // }, [token, updateFlag, selectedIndexName, selectedSector]);


    const fetchIndices = async () => {
    try {
        let dataToFetch;

        if (selectedIndexName === 'Portfolio') {
            if (!token) {
                console.warn('Attempted to fetch Portfolio without authentication');
                return;
            }
            dataToFetch = [{
                name: 'Portfolio',
                api_name: 'protected/favourite',
                requiresAuth: true
            }];
        } else if (selectedIndexName) {
            dataToFetch = [indices.find(index => index.name === selectedIndexName)];
        } else {
            dataToFetch = indices.filter(index => index.name !== 'Portfolio');
        }

        const responses = await Promise.all(dataToFetch.map(index => 
            index.requiresAuth
                ? axiosInstance.get(`/constituents/${index.api_name}?sector=${selectedSector}`, {
                    headers: { Authorization: `Bearer ${token}` }
                  })
                : axiosInstance.get(`/constituents/${index.api_name}?sector=${selectedSector}`)
        ));

        const data = responses.map(response => response.data);

        setIndices(prevIndices => 
            prevIndices.map(index => {
                const fetchedData = data.find((_, idx) => dataToFetch[idx].name === index.name);
                return fetchedData 
                    ? { ...index, constituents: fetchedData }
                    : index;
            })
        );

        if (selectedIndexName) {
            setSelectedConstituents(data[0]);
        }

    } catch (error) {
        console.error('Error fetching indices data:', error);
    }
};

    const handleIndexClick = async (indexName, event) => {
        if (indexName === 'Portfolio' && !token) {
            setPopupPosition({ x: event.clientX, y: event.clientY });
            setErrorMessage('Sign in to view your portfolio');
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 5000);
            setSelectedIndexName(''); // Clear selected index name if not authenticated
            setSelectedConstituents([]); // Clear selected constituents if not authenticated
            return;
        }

        setSelectedIndexName(indexName);
        setIsSidePanelOpen(false);
        setErrorMessage('');

        const selected = indices.find(index => index.name === indexName);
        if (selected) {
            setShowIndexName(indexName === 'favourite');
            setSelectedConstituents(selected.constituents);
        } else {
            console.error(`Index ${indexName} not found`);
        }
    };

    const debouncedFetchIndices = useCallback(
        debounce(() => {
            fetchIndices();
        }, 300),
        []  // Empty dependency array means this will only be created once
    );

    const handleSectorChange = (sector) => {
        setSelectedSector(sector);
        // setUpdateFlag(prevFlag => !prevFlag); 
        // fetchIndices();
    };

        // Use debouncedFetchIndices in your useEffect
        useEffect(() => {
            if (selectedSector) {
                debouncedFetchIndices();
            }
        }, [selectedSector, debouncedFetchIndices]);

    const [selectedConstituentCik, setSelectedConstituentCik] = useState(null);
    const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false); // State to control the modal

    const handleIconClick = (cik) => {
        setSelectedConstituentCik(cik); 
        setIsComparisonModalOpen(true);
    };

    useEffect(() => {
        if (selectedConstituentCik) {
            // Fetch and show the details of the selected constituent
            // You might need to fetch data here if not already present
        }
    }, [selectedConstituentCik]);

    return (
        <AuthProvider>
            <Router>
                <div className="app">
                    <Header />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <IndexList indices={indices} onIndexClick={handleIndexClick} onSectorChange={handleSectorChange} onSearchTermChange={setSearchTerm} />
                                    {selectedConstituents.length > 0 && (
                                        <div className="index-container">
                                            <ErrorBoundary>
                                                <ConstituentsGrid
                                                    constituents={selectedConstituents}
                                                    // setUpdateFlag={setUpdateFlag}
                                                    showIndexName={showIndexName}
                                                    isSidePanelOpen={isSidePanelOpen}
                                                    setIsSidePanelOpen={setIsSidePanelOpen}
                                                    token={token}
                                                    setErrorMessage={setErrorMessage}
                                                    setPopupPosition={setPopupPosition}
                                                    setShowPopup={setShowPopup}
                                                    searchTerm={searchTerm}
                                                    selectedSector={selectedSector}
                                                    setSelectedConstituentCik={handleIconClick}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/users/verify-email/:token" element={<VerifyEmailPage />} />
                        <Route path="/users/reset-password/:token" element={<ResetPasswordPage />} />
                    </Routes>
                    {/* {selectedConstituentCik && (
                        <ComparisonModal 
                            isOpen={isComparisonModalOpen} 
                            onClose={() => setIsComparisonModalOpen(false)}
                            initialCompanyData={selectedConstituentCik} // Pass selected CIK or data related to it
                        />
                    )} */}
                    {showPopup && (
                        <div className="error-message" style={{ top: popupPosition.y, left: popupPosition.x }}>
                            {errorMessage}
                        </div>
                    )}
                    <CookieBanner />
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
