import axiosInstance from '../../config/axiosConfig';
import { calculateNetScore } from './Utils';

export const fetchComments = async (constituent, setComments, setSortedComments, sort) => {
    // console.log('Fetching comments for company_id:', constituent.company_id);
    try {
        const response = await axiosInstance.get('/comments/combined', {
            params: { company_id: constituent.company_id }
        });
        const combinedData = response.data;
        // console.log('Fetched comments:', combinedData); // Check this log in `fetchComments`

        // Apply sort directly
        applySort(combinedData, sort, setSortedComments);
        setComments(combinedData);
    } catch (error) {
        console.error('Error fetching combined data:', error);
    }
};

const getSortValue = (comment, sortOption) => {
    if (sortOption === 'newest') {
        return new Date(comment.created_at);
    } else if (sortOption === 'popular' || sortOption === 'trending') {
        return calculateNetScore(comment.reactions);
    }
    return 0;
};

const applySort = (commentsData, sortOption, setSortedComments) => {
    const parentComments = commentsData.filter(comment => comment.parent_comment_id === null);
    const childComments = commentsData.filter(comment => comment.parent_comment_id !== null);

    // Sort parent comments
    parentComments.sort((a, b) => {
        const aValue = getSortValue(a, sortOption);
        const bValue = getSortValue(b, sortOption);

        if (sortOption === 'newest') {
            return bValue - aValue; // Newest first
        } else if (sortOption === 'popular' || sortOption === 'trending') {
            return bValue - aValue; // Highest score first
        }
        return 0;
    });

    // Create a map for quick access to child comments by parent_comment_id
    const childCommentMap = new Map();
    childComments.forEach(child => {
        if (!childCommentMap.has(child.parent_comment_id)) {
            childCommentMap.set(child.parent_comment_id, []);
        }
        childCommentMap.get(child.parent_comment_id).push(child);
    });

    // Construct the sorted list
    const sortedComments = [];
    parentComments.forEach(parent => {
        sortedComments.push(parent);
        if (childCommentMap.has(parent.comment_id)) {
            sortedComments.push(...childCommentMap.get(parent.comment_id));
        }
    });

    // console.log('Sorted comments:', sortedComments);
    setSortedComments(sortedComments);
};

export { applySort };



export const handleSortToggle = (event, showSortDropdown, setShowSortDropdown) => {
    event.stopPropagation();
    setShowSortDropdown(!showSortDropdown);
};

export const handleSortClick = (sortOption, setSort, setShowSortDropdown, applySort, comments, setSortedComments) => {
    setSort(sortOption);
    setShowSortDropdown(false);
    applySort(comments, sortOption, setSortedComments);
};

export const toggleSection = (section, setCollapsedSections) => {
    setCollapsedSections(prevState => ({
        ...prevState,
        [section]: !prevState[section]
    }));
};

export const showAlert = () => {
    alert('You need to be logged in to perform this action.');
};

export const handlePostComment = async (user, parentCommentId, userRating, commentText, bottomCommentText, editorRef, bottomEditorRef, setCommentText, setBottomCommentText, setReplyTo, setReplyUserRating, setBottomUserRating, constituent, fetchComments) => {
    if (!user) {
        showAlert();
        return;
    }

    try {
        const payload = {
            user_id: user.user_id,
            company_id: constituent.company_id,
            company_name: constituent.company_name,
            content: parentCommentId ? commentText : bottomCommentText,
            type: 'comment',
            parent_comment_id: parentCommentId || null,
            user_company_rating: userRating // keep this field
        };
        await axiosInstance.post('/comments/add', payload);
        if (parentCommentId) {
            setCommentText('');
            setReplyTo(null);
            setReplyUserRating(5); // Reset reply user rating
        } else {
            setBottomCommentText('');
            setBottomUserRating(5);
        }
        if (editorRef.current) {
            editorRef.current.getEditor().setText('');
        }
        if (bottomEditorRef.current) {
            bottomEditorRef.current.getEditor().setText('');
        }
        fetchComments();
    } catch (error) {
        console.error('Error posting comment:', error);
    }
};

export const handleReplyClick = (user, comment, setReplyTo, showAlert, editorRef) => {
    // console.log('handleReplyClick:', { user, commentId: comment.comment_id });
    if (!user) {
        showAlert();
        return;
    }

    setReplyTo(comment);
    if (editorRef.current) {
        editorRef.current.getEditor().focus();
    }
};

export const handleLikeClick = async (user, commentId, showAlert, fetchComments) => {
    // console.log('handleLikeClick:', { user, commentId });
    if (!user) {
        showAlert();
        return;
    }

    try {
        await axiosInstance.post(`/comments/like`, { comment_id: commentId, user_id: user.user_id });
        fetchComments(); // Refresh comments
    } catch (error) {
        console.error('Error liking comment:', error);
    }
};

export const handleDislikeClick = async (user, commentId, showAlert, fetchComments) => {
    // console.log('handleDislikeClick:', { user, commentId });
    if (!user) {
        showAlert();
        return;
    }

    try {
        await axiosInstance.post(`/comments/dislike`, { comment_id: commentId, user_id: user.user_id });
        fetchComments();
    } catch (error) {
        console.error('Error disliking comment:', error);
    }
};

export const handleFlagClick = async (user, commentId, showAlert, fetchComments) => {
    // console.log('handleFlagClick:', { user, commentId });
    if (!user) {
        showAlert();
        return;
    }

    try {
        await axiosInstance.post(`/comments/flag/${commentId}`, { user_id: user.user_id });
        fetchComments();
    } catch (error) {
        console.error('Error flagging comment:', error);
    }
};

export const handleDeleteClick = async (user, commentId, showAlert, fetchComments) => {
    // console.log('handleDeleteClick:', { user, commentId });
    if (!user) {
        showAlert();
        return;
    }

    try {
        await axiosInstance.delete(`/comments/${commentId}`, {
            data: { user_id: user.user_id }
        });
        fetchComments();
    } catch (error) {
        console.error('Error deleting comment:', error);
    }
};
