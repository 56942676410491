// CookieBanner.js

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import '../dist/styles/CookieBanner.min.css';


const CookieBanner = () => {
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');
        if (cookieConsent === 'accepted') {
            setAccepted(true);
        }
    }, []);

    const acceptCookies = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setAccepted(true);
    };

    if (accepted) {
        return null; // Don't render the banner if cookies are accepted
    }

    return (
        <div className="cookie-banner">
            <p>This website uses cookies to ensure you get the best experience on our website.</p>
            <button onClick={acceptCookies}>Accept</button>
            <Link to="/privacy">Learn more</Link>
            </div>
    );
};

export default CookieBanner;
