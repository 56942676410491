import React, { useState, useEffect, useRef, useContext } from 'react';
import '../dist/styles/IndexList.min.css';
import { AuthContext } from './membersarea/contexts/AuthContext';
import ComparisonModal from './companyComparisonModal';

const IndexList = ({ indices = [], onIndexClick, onSectorChange, onSearchTermChange }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [sectors, setSectors] = useState([
        'Consumer Discretionary', 'Consumer Staples', 'Energy', 'Financials', 'Health Care',
        'Industrials', 'Information Technology', 'Materials', 'Real Estate', 'Utilities'
    ]);
    const [selectedSector, setSelectedSector] = useState('all');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);
    const [selectedConstituentCik, setSelectedConstituentCik] = useState(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleIndexClick = (indexName, index) => {
        if (indexName === 'Portfolio' && !user) {
            alert('Sign in to view your portfolio');
            return; // Prevent clicking on Portfolio if the user is not signed in
        }
        setActiveIndex(index);
        onIndexClick(indexName);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleIconClick = (cik) => {
        // console.log(`cik: ${cik}`);
        setSelectedConstituentCik(cik);
        setIsComparisonModalOpen(true);
    };

    const handleSectorClick = (sector) => {
        setSelectedSector(sector);
        if (onSectorChange) {
            onSectorChange(sector);
        }
        setShowDropdown(false);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSearchIconClick = () => {
        setShowSearchInput(!showSearchInput);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        if (onSearchTermChange) {
            onSearchTermChange(newSearchTerm); // Notify the parent component about the change
        }
    };

    const handleGetStarted = () => {
        // Find the index of "S&P 500" in the indices array
        const spIndex = indices.findIndex(index => index.name === "S&P 500");
        if (spIndex !== -1) {
            handleIndexClick("S&P 500", spIndex);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <div className="index-list">
                <ul className="index-tabs">
                    {indices.map((index, idx) => (
                        <li
                            key={index.name}
                            className={`index-tab ${activeIndex === idx ? 'active' : ''}`}
                            onClick={() => handleIndexClick(index.name, idx)}
                        >
                            {index.displayStar && <i className="fas fa-star"></i>} {index.name}
                        </li>
                    ))}
                    {/* <li>
                        <button 
                            className="view-icon-button index-tab" 
                            style={{ marginRight: '10px', backgroundColor: 'transparent', border: 'none' }}
                            onClick={() => handleIconClick("320193")}
                        >
                        <i class="fas fa-chart-bar"></i>
                            &nbsp; Compare Co's
                        </button>
                    </li> */}
                </ul>
                <div className="sort2-container" ref={dropdownRef}> 
                    <i className="fas fa-search search-icon" onClick={handleSearchIconClick}></i>
                    {showSearchInput && (
                        <input
                            type="text"
                            className="search-input"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search stocks..."
                        />
                    )}
                    <button className="sort2-button" onClick={toggleDropdown}>
                        <i className="fas fa-filter blue-icon"></i>
                        <div className='mobile-hide'> &nbsp; Sector </div>
                    </button>
                    <ul className={`sort2-dropdown ${showDropdown ? 'show' : ''}`}>
                        <li
                            className={`sort2-option ${selectedSector === 'all' ? 'active' : ''}`}
                            onClick={() => handleSectorClick('all')}
                        >
                            All Sectors
                        </li>
                        {sectors.map((sector) => (
                            <li
                                key={sector}
                                className={`sort2-option ${selectedSector === sector ? 'active' : ''}`}
                                onClick={() => handleSectorClick(sector)}
                            >
                                {sector}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <>
                {isComparisonModalOpen && (
                    <ComparisonModal 
                        isOpen={isComparisonModalOpen} 
                        onClose={() => setIsComparisonModalOpen(false)} 
                        initialCompanyData={selectedConstituentCik} 
                    />
                )}
            </>
            {activeIndex === null && ( // Render welcome message if no index is selected
            <div className="welcome-container">
                <h1 className='welcome-title'>Investing Reimagined: Open Data, Shared Wisdom</h1>
                <h3 className="mobile-mission">Empowering individual investors with institutional-grade tools and data. We foster community-driven financial analysis, democratizing access to market insights.</h3>

            
                <div className="welcome-grid">
                    <div className="grid-item mission desktop-only">
                        <h3 className=''>Our Mission</h3>
                        <p className="desktop-mission">OpenEquity puts the power of institutional-grade financial analysis in your hands. We're leveling the playing field, giving individual investors the tools and data traditionally reserved for Wall Street. Our platform fosters a community-driven approach to financial analysis, enabling users to share insights, make informed decisions, and participate in financial markets—regardless of their capital size.</p>
                    </div>
            
                    <div className="grid-item feature">
                        <h3>Explore 570+ Global Companies</h3>
                        <p>Access comprehensive financial data spanning over a decade for more than 570 global companies. Dive deep into their financial history and performance.</p>
                        {/* Insert screenshot here */}
                    </div>
            
                    <div className="grid-item feature">
                        <h3>Visual Financial Insights</h3>
                        <p>Analyze trends with our interactive graphical representations. Visualize complex financial data in intuitive, easy-to-understand formats.</p>
                        {/* Insert screenshot here */}
                    </div>
            
                    <div className="grid-item feature">
                        <h3>Standardized Comparisons</h3>
                        <p>Compare companies using our simplified, standardized set of financial categories. Make apples-to-apples comparisons across different industries and regions.</p>
                        {/* Insert screenshot here */}
                    </div>
            
                    <div className="grid-item community">
                        <h3>Join Our Community</h3>
                        <p>Engage with fellow investors, share insights, and collaborate on financial analysis. Your contributions help build a more transparent and accessible financial ecosystem.</p>
                    </div>
            
                    <div className="grid-item cta">
                        <h3>Ready to Start?</h3>
                        <p>Click the button below to explore the S&P 500 and begin your journey towards more informed investment decisions.</p>
                        <button className="get-started-btn" onClick={handleGetStarted}>Get Started</button>
                    </div>
                </div>
            </div>
       
            )}
        </div>
    );
};

export default IndexList;
