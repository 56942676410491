import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { capitalizeWords } from './financialViewsHelpers';
import { graphColors, pAndLOrder, cashFlowOrder, balanceSheetOrder } from './financialViewsHelpersJSONs';
import LabelsForReview from './LabelReviewSection';

const GraphicalView = ({
    combinedData,
    filteredLabelDescription,
    breakdownMethod,
    setBreakdownMethod,
    selectedAccounts,
    setSelectedAccounts,
    selectedAccountDescription,
    setSelectedAccountDescription,
    toggleSection,
    setCollapsedSections,
    collapsedSections,
    startYear,
    endYear,
    selectedUnit,
    displayUnit,
    constituent,
    setViewMethod,
    modelCOAData // New prop
}) => {
    const chartRef = useRef(null);
    const myChartRef = useRef(null);
    const [activeTab, setActiveTab] = useState('P&L Statement');
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [expandedLabels, setExpandedLabels] = useState({});
    // const aspectRatio = 300 / 600; // Height / Width

    useEffect(() => {
        // Set the initial selected account if there are no selected accounts
        if (combinedData.length > 0 && selectedAccounts.length === 0 && selectedUnit) {
            const initialAccount = breakdownMethod === 'raw'
                ? combinedData.find(data => data.unit?.toLowerCase() === selectedUnit.toLowerCase())
                : combinedData.find(data => data.detailed_category && data.unit?.toLowerCase() === selectedUnit.toLowerCase());

            if (initialAccount) {
                setSelectedAccounts([{ account: breakdownMethod === 'raw' ? initialAccount.label : initialAccount.detailed_category, unit: initialAccount.unit.toLowerCase() }]);
            }
        }
    }, [combinedData, selectedAccounts, selectedUnit, breakdownMethod, setSelectedAccounts]);

    useEffect(() => {
        // console.log("modelCOAData:", modelCOAData); // Log modelCOAData to see its structure
        // console.log("filteredLabelDescription:", filteredLabelDescription); // Log filteredLabelDescription to see its structure

        if (selectedAccounts.length > 0 && selectedUnit) {
            let financialData = combinedData.filter(data =>
                breakdownMethod === 'raw'
                    ? selectedAccounts.map(acc => acc.account?.toLowerCase()).includes(data.label?.toLowerCase()) && data.unit?.toLowerCase() === selectedUnit?.toLowerCase()
                    : selectedAccounts.map(acc => acc.account?.toLowerCase()).includes(data.detailed_category?.toLowerCase()) && data.unit?.toLowerCase() === selectedUnit?.toLowerCase()
            );

            if (breakdownMethod === 'categorized') {
                const groupedData = financialData.reduce((acc, curr) => {
                    const date = curr.end;
                    const unit = curr.unit?.toLowerCase();
                    if (!acc[date]) {
                        acc[date] = {};
                    }
                    if (!acc[date][unit]) {
                        acc[date][unit] = {};
                    }
                    if (!acc[date][unit][curr.detailed_category]) {
                        acc[date][unit][curr.detailed_category] = 0;
                    }
                    acc[date][unit][curr.detailed_category] += curr.value;
                    return acc;
                }, {});

                financialData = Object.keys(groupedData).reduce((acc, date) => {
                    const dateData = groupedData[date];
                    Object.keys(dateData).forEach(unit => {
                        const unitData = dateData[unit];
                        Object.keys(unitData).forEach(category => {
                            acc.push({
                                end: date,
                                detailed_category: category,
                                value: unitData[category],
                                unit: unit
                            });
                        });
                    });
                    return acc;
                }, []);
            }

            financialData = financialData.filter(data => data.end >= startYear && data.end <= endYear);

            const labels = [...new Set(financialData.map(data => data.end))].sort((a, b) => new Date(a) - new Date(b));
            const datasets = selectedAccounts.map((account, index) => {
                const [category, unit] = account.account.split(' - ').map(item => item.toLowerCase());
                const accountData = financialData.filter(data =>
                    breakdownMethod === 'raw'
                        ? data.label?.toLowerCase() === account.account?.toLowerCase() && data.unit?.toLowerCase() === selectedUnit?.toLowerCase()
                        : data.detailed_category?.toLowerCase() === category && data.unit?.toLowerCase() === selectedUnit?.toLowerCase()
                );
                const values = labels.map(label => {
                    const dataPoint = accountData.find(data => data.end === label);
                    return dataPoint ? dataPoint.value / displayUnit : 0; // Use display unit
                });

                return {
                    label: capitalizeWords(account.account),
                    data: values,
                    borderColor: graphColors[index % graphColors.length],
                    backgroundColor: `${graphColors[index % graphColors.length]}33`, // 20% opacity
                    borderWidth: 1,
                };
            });

            if (myChartRef.current) {
                myChartRef.current.destroy();
            }

            if (datasets.length > 0) {
                const ctx = chartRef.current.getContext('2d');

                 // Determine the appropriate unit label
                 let unitLabel = "";
                 if (displayUnit === 1e9) {
                     unitLabel = "Billions";
                 } else if (displayUnit === 1e6) {
                     unitLabel = "Millions";
                 } else if (displayUnit === 1e3) {
                     unitLabel = "Thousands";
                 } else {
                     unitLabel = "";
                 }

                 myChartRef.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: datasets.map((dataset, index) => ({
                            ...dataset,
                            borderColor: graphColors[index % graphColors.length],
                            // backgroundColor: (context) => {
                            //     const ctx = context.chart.ctx;
                            //     const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                            //     const color = graphColors[index % graphColors.length];
                            //     gradient.addColorStop(0, `${color}33`); // 20% opacity
                            //     gradient.addColorStop(1, `${color}00`); // 0% opacity
                            //     return gradient;
                            // },
                            backgroundColor: 'transparent', // 20% opacity
                            fill: false,
                            borderWidth: 3,
                            pointRadius: 0,
                            tension: 0.4,
                            fill: true
                        }))
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: `${constituent.company_name}'s Financials (${selectedUnit.toUpperCase()}, ${unitLabel})`,
                                font: {
                                    size: 16,
                                    weight: 'bold'
                                },
                                padding: {
                                    top: 10,
                                    bottom: 30
                                }
                            },
                            legend: {
                                display: false,
                                position: 'bottom'
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function(context) {
                                        let label = context.dataset.label || '';
                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed.y !== null) {
                                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: selectedUnit.toUpperCase(), minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(context.parsed.y);
                                        }
                                        return label;
                                    }
                                }
                            }
                        },
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'year',
                                    displayFormats: {
                                        year: 'yyyy'
                                    }
                                },
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    font: {
                                        size: 12,
                                        weight: 'bold'
                                    }
                                }
                            },
                            y: {
                                beginAtZero: true,
                                grid: {
                                    display: true
                                },
                                ticks: {
                                    font: {
                                        size: 12,
                                        weight: 'bold'
                                    },
                                    callback: function(value) {
                                        return value.toLocaleString();
                                    }
                                }
                            }
                        }
                    }
                });

                const accountDescription = filteredLabelDescription.find(desc =>
                    breakdownMethod === 'raw'
                        ? desc.label?.toLowerCase() === selectedAccounts[0].account?.toLowerCase()
                        : desc.detailed_category?.toLowerCase() === selectedAccounts[0].account.split(' - ')[0]?.toLowerCase() && desc.unit?.toLowerCase() === selectedAccounts[0].account.split(' - ')[1]?.toLowerCase()
                );

                setSelectedAccountDescription(accountDescription ? accountDescription.description : 'No description available');
            }
        }
    }, [selectedAccounts, combinedData, filteredLabelDescription, breakdownMethod, startYear, endYear, selectedUnit, displayUnit]);

    useEffect(() => {
        const updateCanvasSize = () => {
            const canvas = chartRef.current;
            if (canvas) {
                // const containerWidth = canvas.parentElement.offsetWidth;
                // canvas.style.height = `${containerWidth * aspectRatio}px`;
            }
        };

        updateCanvasSize(); // Initial size update
        window.addEventListener('resize', updateCanvasSize); // Update on resize

        return () => window.removeEventListener('resize', updateCanvasSize); // Cleanup
    }, []);

    const handleAccountSelect = (event) => {
        const value = event.target.value.toLowerCase();
        const selectedAccount = combinedData.find(data =>
            breakdownMethod === 'raw'
                ? data.label && data.label.toLowerCase() === value
                : data.detailed_category && data.detailed_category.toLowerCase() === value && data.unit?.toLowerCase() === selectedUnit.toLowerCase()
        );

        if (!selectedAccount) return;

        const accountWithUnit = { account: value, unit: selectedAccount.unit?.toLowerCase() };

        if (selectedAccounts.map(acc => acc.account.toLowerCase()).includes(value)) {
            setSelectedAccounts(selectedAccounts.filter(acc => acc.account.toLowerCase() !== value));
        } else {
            setSelectedAccounts([...selectedAccounts, accountWithUnit]);
        }
    };

    const handleRemoveAccount = (account) => {
        setSelectedAccounts(selectedAccounts.filter(acc => acc.account.toLowerCase() !== account.toLowerCase()));
    };

    const handleBreakdownMethodChange = (method) => {
        setBreakdownMethod(method);
        setSelectedAccounts([]);

        if (method === 'categorized' && combinedData.length > 0) {
            const initialCategorizedAccount = combinedData.find(data => data.detailed_category);
            if (initialCategorizedAccount) {
                setSelectedAccounts([{ account: initialCategorizedAccount.detailed_category, unit: initialCategorizedAccount.unit.toLowerCase() }]);
            }
        }

    };

    const toggleDescription = (index) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const toggleLabel = (index) => {
        setExpandedLabels(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const renderDropdown = (title, order, filterMethod, placeholder) => (
        <>
            <select className="custom-select" id={`${title.toLowerCase().replace(/ /g, '-')}-select`} onChange={handleAccountSelect}>
                <option value="" className="default-option">{placeholder}</option>
                {order.filter(item =>
                    combinedData.some(data => filterMethod === 'categorized' && data.detailed_category === item) ||
                    combinedData.some(data => filterMethod === 'raw' && data.financial_statement === title && data.label === item)
                ).map((item, index) => (
                    <option key={index} value={item} className={`custom-option ${selectedAccounts.map(acc => acc.account?.toLowerCase()).includes(item.toLowerCase()) ? 'selected' : ''}`}>
                        {item}
                    </option>
                ))}
            </select>
        </>
    );

    const renderRawDropdown = (title, order, placeholder) => (
        <>
            <select className="custom-select" id={`${title.toLowerCase().replace(/ /g, '-')}-select`} onChange={handleAccountSelect}>
                <option value="" className="default-option">{placeholder}</option>
                {combinedData
                    .filter(data => data.financial_statement === title)
                    .map(data => capitalizeWords(data.label))
                    .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                    .map((item, index) => (
                        <option key={index} value={item} className={`custom-option ${selectedAccounts.map(acc => acc.account?.toLowerCase()).includes(item.toLowerCase()) ? 'selected' : ''}`}>
                            {item}
                        </option>
                    ))}
            </select>
        </>
    );

    return (
        <>
            {selectedUnit && (
                <>
                    <div className='data-section'> 
                        <h3 className='data-header'> View {constituent.company_name}'s financial accounts by both <span
                                                className={`pill ${breakdownMethod === 'raw' ? 'active' : ''}`}
                                                onClick={() => handleBreakdownMethodChange('raw')}
                                            >
                                                Original <i className="fas fa-info-circle tooltip"><span className="tooltiptextgraph">As per SEC listing</span></i>
                                            </span> and  <span
                                                className={`pill ${breakdownMethod === 'categorized' ? 'active' : ''}`}
                                                onClick={() => handleBreakdownMethodChange('categorized')}
                                            >
                                                Summarised <i className="fas fa-info-circle tooltip"><span className="tooltiptextgraph">Based on community mappings</span></i> <i className="fas fa-beta"></i>
                                            </span></h3>
                        
                                            <div className='account-selector'>
                                    <div className='mob-relative'>
                                        <div className="tabs">
                                            <span className={`tab ${activeTab === 'P&L Statement' ? 'active' : ''}`} onClick={() => setActiveTab('P&L Statement')}>P&L Statement</span>
                                            <span className={`tab ${activeTab === 'Cash Flow Statement' ? 'active' : ''}`} onClick={() => setActiveTab('Cash Flow Statement')}>Cash Flow Statement</span>
                                            <span className={`tab ${activeTab === 'Balance Sheet' ? 'active' : ''}`} onClick={() => setActiveTab('Balance Sheet')}>Balance Sheet</span>
                                        </div>
                                        <div className='graph-legend-filter'>
                                        {breakdownMethod === 'categorized' && activeTab === 'P&L Statement' && renderDropdown('P&L Statement', pAndLOrder, 'categorized', 'Add P&L Acct.')}
                                        {breakdownMethod === 'categorized' && activeTab === 'Cash Flow Statement' && renderDropdown('Cash Flow Statement', cashFlowOrder, 'categorized', 'Add Cash Flow Acct.')}
                                        {breakdownMethod === 'categorized' && activeTab === 'Balance Sheet' && renderDropdown('Balance Sheet', balanceSheetOrder, 'categorized', 'Add Balance Sheet Acct.')}
                                        {breakdownMethod === 'raw' && activeTab === 'P&L Statement' && renderRawDropdown('P&L Statement', pAndLOrder, 'Add P&L Acct.')}
                                        {breakdownMethod === 'raw' && activeTab === 'Cash Flow Statement' && renderRawDropdown('Cash Flow Statement', [], 'Add Cash Flow Acct.')}
                                        {breakdownMethod === 'raw' && activeTab === 'Balance Sheet' && renderRawDropdown('Balance Sheet', [], 'Add Balance Sheet Acct.')}
                                        </div>
                                    </div>
                                </div>               
                    </div>

                    {/* <LabelsForReview /> */}
                    <div className='full-width'>
                        <div className="chart-and-accounts">
                                {selectedAccounts.length > 0 && (
                                    <div className='chart-container'>
                                        <canvas ref={chartRef} style={{ maxWidth: '800px', maxHeight: '400px' }}></canvas>
                                    </div>
                                )}
                            <div className="selected-accounts">
                        {selectedAccounts.map((account, index) => (
                            <span key={index} className="pill" style={{ backgroundColor: graphColors[index % graphColors.length], position: 'relative', color: 'white', border: 'none' }}>
                                <i className="fas fa-times" onClick={() => handleRemoveAccount(account.account)}></i>
                                <i className="fas fa-info-circle tooltip">
                                    <span className="tooltiptext" style={{ border: `1px solid ${graphColors[index % graphColors.length]}`}}>
                                     {filteredLabelDescription.find(desc => breakdownMethod === 'raw' ? desc.label?.toLowerCase() === account.account?.toLowerCase() : desc.detailed_category?.toLowerCase() === account.account.split(' - ')[0]?.toLowerCase())?.description}
                                    </span>
                                </i>
                                &nbsp; {capitalizeWords(account.account)} 
                            </span>
                        ))}
                    </div>
                          
                        </div>
                    </div>
                    <div className="label-description-section">
                        <div className="section-header comments-section-header" onClick={() => toggleSection('labelDescription', setCollapsedSections)}>
                            <div className='account-toggle-group collapsible-leftside'>
                                <div className='h-no-margin desc-toggle'>View {breakdownMethod === 'raw' ? 'raw' : 'categorized'} accounts and descriptions</div>
                                <i className={`fas ${collapsedSections.labelDescription ? 'fa-plus-circle' : 'fa-minus-circle'}`}></i>
                            </div>
                        </div>
                    
                        {!collapsedSections.labelDescription && (
                            <div className="account-desc">
                                <table className="data-table">
                                    <thead>
                                        <tr>
                                            <th className="header-cell label-column">{breakdownMethod === 'raw' ? 'Label' : 'Detailed Category'}</th>
                                            <th className="header-cell description-column">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {breakdownMethod === 'raw' && filteredLabelDescription.map((labelDesc, index) => (
                                            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                <td className="label-column">
                                                    {labelDesc.label.length > 100 ? (
                                                        <>
                                                            {expandedLabels[index] ? labelDesc.label : `${labelDesc.label.substring(0, 80)}`}
                                                            <i
                                                                className={`fas ${expandedLabels[index] ? 'fa-minus-circle' : 'fa-plus-circle'}`}
                                                                onClick={() => toggleLabel(index)}
                                                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                            ></i>
                                                        </>
                                                    ) : (
                                                        labelDesc.label
                                                    )}
                                                </td>
                                                <td className="description-column">
                                                    {labelDesc.description.length > 100 ? (
                                                        <>
                                                            {expandedDescriptions[index] ? labelDesc.description : `${labelDesc.description.substring(0, 80)}`}
                                                            <i
                                                                className={`fas ${expandedDescriptions[index] ? 'fa-minus-circle' : 'fa-plus-circle'}`}
                                                                onClick={() => toggleDescription(index)}
                                                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                            ></i>
                                                        </>
                                                    ) : (
                                                        labelDesc.description
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                       {breakdownMethod === 'categorized' && modelCOAData.map((item, index) => (
                                            item.detailedCategories.map((detailedCategory, subIndex) => (
                                                <tr key={`${index}-${subIndex}`} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                    <td className="label-column">
                                                        {detailedCategory.length > 80 ? (
                                                            <>
                                                                {expandedLabels[`${index}-${subIndex}`] ? detailedCategory : `${detailedCategory.substring(0, 80)}`}
                                                                <i
                                                                    className={`fas ${expandedLabels[`${index}-${subIndex}`] ? 'fa-minus-circle' : 'fa-plus-circle'}`}
                                                                    onClick={() => toggleLabel(`${index}-${subIndex}`)}
                                                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                                ></i>
                                                            </>
                                                        ) : (
                                                            detailedCategory
                                                        )}
                                                    </td>
                                                    <td 
                                                        className={`description-column ${expandedDescriptions[`${index}-${subIndex}`] ? 'expanded' : ''}`} 
                                                        onClick={() => toggleDescription(`${index}-${subIndex}`)}
                                                    >
                                                        {item.definition}
                                                    </td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

<div className='data-explanation mob-hide'> Summarised account mappings are subject to community review and so may not be correctly mapped.</div>
                        <div className='data-explanation mb-20'>
                            To update
                            <div className='mob-show' style={{ display: 'none' }}>
                                &nbsp; mappings
                            </div>
                            , use <u><b><i onClick={() => setViewMethod('communityData')} style={{ cursor: 'pointer' }}>Community Data Mapping</i></b></u> tab
                        </div> 
                    </div>
                </>
            )}
        </>
    );
};

export default GraphicalView;
