import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check for user data in localStorage or through an API call
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
    }, []);

    const signIn = (userData) => {
        // console.log('SignIn called with:', userData); // Log userData
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('accessToken', userData.token); // Assuming userData has a token property
        window.location.reload();
    };

    const signOut = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken'); // Clear the token as well
        window.location.reload(); // Reload the page to ensure the state is cleared
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};
