import React, { useState, useContext } from 'react';
import axiosInstance from '../../config/axiosConfig'; // Import axios instance configured for your backend API
import { AuthContext } from './contexts/AuthContext';

const SignIn = ({ onClose }) => {
    const { signIn } = useContext(AuthContext);
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // New loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        
        try {
            // Send login request to backend
            const response = await axiosInstance.post('/users/login', { usernameOrEmail, password });

            // Assuming response.data contains both 'user' and 'token' fields
            const { user, token } = response.data;

            // Store the token in localStorage
            localStorage.setItem('accessToken', token);

            // Call context function to set authenticated user
            signIn({ ...user, token });

            // Close modal after successful sign-in
            onClose();
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.error); // Handle backend error message
            } else {
                setError('Something went wrong. Please try again.'); // Handle generic error
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleInputChange = (e) => {
        setUsernameOrEmail(e.target.value);
    };

    return (
        <div className="form-container">
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Email or Username"
                    value={usernameOrEmail}
                    onChange={handleInputChange}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    type="submit"
                    className={`sign-in-button ${usernameOrEmail && password ? 'enabled' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <i className="fas fa-spinner fa-spin"></i> Signing In...
                        </>
                    ) : (
                        'Sign In'
                    )}
                </button>
            </form>
        </div>
    );
};

export default SignIn;
