import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axiosConfig';

const VerifyEmailPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axiosInstance.get(`/users/verify-email/${token}`);
                if (response.status === 200) {
                    alert('Email verified successfully! You can now log in.');
                    navigate('/');
                } else {
                    alert('Invalid or expired token.');
                }
            } catch (error) {
                console.error('Error verifying email:', error);
                alert('Error verifying email. Please try again.');
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <div>
            <p>Verifying email...</p>
        </div>
    );
};

export default VerifyEmailPage;
