import React, { useRef, useContext } from 'react';
import UserArea from './UserArea'; // Import the UserArea component
import { AuthContext } from './contexts/AuthContext'; // Import AuthContext to access user information
import '../../dist/styles/Modal.min.css';
import img1 from '../../assets/greyLogo0.5x.png';

const UserAreaModal = ({ onClose }) => {
    const modalRef = useRef(null);
    const { user } = useContext(AuthContext); // Fetch user from AuthContext

    // console.log(`user: ${JSON.stringify(user, null, 2)}`); // Pretty-print the user object


    const handleModalClose = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose(); // Close the modal if clicked outside the modal content
        }
    };

    return (
        <>
            <div className="site-overlay" onClick={onClose}></div>
            <div className="modal-overlay" onClick={handleModalClose}>
                <div className="modal-content" ref={modalRef}>
                    <div className="modal-header">
                        <div>
                            <img src={img1} alt="Logo" className="logo-image" />
                        </div>
                        <h2 className=''>{user.username}</h2>
                        <i className="fas fa-times modal-close-icon" onClick={onClose} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <UserArea onClose={onClose} /> {/* Render the UserArea component */}
                </div>
            </div>
        </>
    );
};

export default UserAreaModal;
