import React, { useEffect, useRef } from 'react';

const TradingViewMiniWidgetLazy = ({ symbol = "NASDAQ:AAPL", width = "100%", height = 240, colorTheme = "light" }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;

    const config = {
      symbol: symbol,
      width: width,
      height: height,
      locale: "en",
      dateRange: "3M",
      colorTheme: colorTheme,
      isTransparent: true,
      autosize: false,
      largeChartUrl: ""
    };

    script.innerHTML = JSON.stringify(config);

    const container = containerRef.current;
    if (container) {
      container.innerHTML = ''; // Clear previous content
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.innerHTML = ''; // Clean up on unmount
      }
    };
  }, [symbol, width, height, colorTheme]);

  return (
    <div className="tradingview-widget-container" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewMiniWidgetLazy;
