// financialViewsHelpersJSONs.js
export const currencySymbols = {
    'aed': 'د.إ',
    'afn': '؋',
    'ars': '$',
    'aud': '$',
    'brl': 'R$',
    'cad': '$',
    'chf': 'CHF',
    'cny': '¥',
    'czk': 'Kč',
    'eur': '€',
    'gbp': '£',
    'hkd': 'HK$',
    'inr': '₹',
    'jpy': '¥',
    'krw': '₩',
    'mxn': '$',
    'myr': 'RM',
    'nok': 'kr',
    'nzd': '$',
    'php': '₱',
    'rub': '₽',
    'sar': '﷼',
    'sgd': 'S$',
    'twd': 'NT$',
    'usd': '$',
    'zar': 'R'
};

export const graphColors = [
    '#B287F9', '#EB99C1', '#A3CFBB', '#FEBF8A',
    '#96B2E5', '#6AD0B5', '#EA868F', '#FDDF92'
];

export const pAndLOrder = [
    'Sales Revenue', 'Other Operating Revenue', 'Direct Costs of Production', 'Gross Profit',
    'Selling, General and Administrative Expenses (SG&A)', 'Research and Development Expenses (R&D)',
    'Depreciation and Amortization', 'EBITDA', 'EBIT', 'Interest Income', 'Interest Expense',
    'Gain/Loss on Sale of Assets', 'Other Non-Operating Income/Expenses', 'Income Before Tax',
    'Income Tax Expense', 'Net Income'
];

export const cashFlowOrder = [
    'Net Income', 'Adjustments for Non-Cash Items', 'Changes in Working Capital', 'Other Operating Activities',
    'Purchase of Property, Plant, and Equipment (Capital Expenditures)', 'Proceeds from Sale of Assets',
    'Investments in Securities', 'Proceeds from Sale/Maturity of Investments', 'Proceeds from Issuance of Equity or Debt',
    'Repayment of Debt', 'Payment of Dividends', 'Repurchase of Stock', 'Net Increase (Decrease) in Cash and Cash Equivalents',
    'Cash and Cash Equivalents at Beginning of Period', 'Cash and Cash Equivalents at End of Period'
];

export const balanceSheetOrder = [
    'Current Assets: Cash and Cash Equivalents', 'Current Assets: Accounts Receivable', 'Current Assets: Inventory',
    'Current Assets: Prepaid Expenses', 'Current Assets: Other Current Assets', 'Non-Current Assets: Property, Plant, and Equipment (PPE)',
    'Non-Current Assets: Intangible Assets', 'Non-Current Assets: Long-term Investments', 'Non-Current Assets: Accounts Receivable',
    'Non-Current Assets: Other Non-Current Assets', 'Total Current Assets', 'Total Non-Current Assets', 'Total Assets',
    'Current Liabilities: Accounts Payable', 'Current Liabilities: Short-term Debt', 'Current Liabilities: Accrued Liabilities',
    'Current Liabilities: Other Current Liabilities', 'Current Liabilities: Deferred Tax Liabilities', 'Non-current Liabilities: Accounts Payable',
    'Non-Current Liabilities: Long-term Debt', 'Non-Current Liabilities: Deferred Tax Liabilities', 'Non-Current Liabilities: Other Non-Current Liabilities',
    'Total Current Liabilities', 'Total Non-Current Liabilities', 'Total Liabilities', 'Common Stock', 'Retained Earnings',
    'Additional Paid-In Capital', 'Other Comprehensive Income', 'Treasury Stock (if applicable)', 'Total Equity', 'Total Liabilities and Equity',
    'Non-Current Assets: Inventory'
];
