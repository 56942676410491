// src/components/ScrollToTopButton.js


import React, { useState, useEffect } from 'react';
import '../dist/styles/ScrollToTopButton.min.css'; // Ensure correct path

const ScrollToTopButton = ({ constituentsGridRef }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (constituentsGridRef.current) {
                const scrollTop = constituentsGridRef.current.scrollTop;
                setIsVisible(scrollTop > 100);
            }
        };

        const currentRef = constituentsGridRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', toggleVisibility);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', toggleVisibility);
            }
        };
    }, [constituentsGridRef]);

    const scrollToTop = () => {
        if (constituentsGridRef.current) {
            constituentsGridRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="scroll-to-top-button">
            {isVisible && (
                <button onClick={scrollToTop}>
                    <i className="fas fa-arrow-up"></i>
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
