import React, { useContext, useState } from 'react';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from '../../config/axiosConfig';
import '../../dist/styles/Modal.min.css';

const UserArea = ({ onClose }) => {
  const { user, setUser, signOut } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState('');
  const [passwordRequirementsError, setPasswordRequirementsError] = useState('');
  const [activeTab, setActiveTab] = useState('password'); // Default to 'password'
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resendMessage, setResendMessage] = useState('');

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar };
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    const { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = validatePassword(newPassword);

    let errorMessages = [];

    if (!minLength) {
      errorMessages.push('At least 8 characters');
    }
    if (!hasUpperCase) {
      errorMessages.push('One uppercase letter');
    }
    if (!hasLowerCase) {
      errorMessages.push('One lowercase letter');
    }
    if (!hasNumber) {
      errorMessages.push('One number');
    }
    if (!hasSpecialChar) {
      errorMessages.push('One special character');
    }

    if (confirmPassword && newPassword !== confirmPassword) {
      setUpdatePasswordMessage('Passwords do not match.');
    } else if (errorMessages.length > 0) {
      setPasswordRequirementsError(`Password must include: ${errorMessages.join(', ')}`);
      setUpdatePasswordMessage('');
    } else {
      setPasswordRequirementsError('');
      setUpdatePasswordMessage('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    if (newPassword !== confirmPassword) {
      setUpdatePasswordMessage('Passwords do not match.');
    } else {
      setUpdatePasswordMessage('');
    }
  };

  const handleUpdatePassword = async () => {
    if (passwordRequirementsError || updatePasswordMessage) return;

    try {
      const response = await axiosInstance.put('/users/password', { user_id: user.user_id, newPassword, oldPassword: currentPassword });
      setUpdatePasswordMessage(response.data.message);
      setNewPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      setUpdatePasswordMessage('Error updating password. Please try again.');
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSignOut = () => {
    handleClose();
    signOut();
  };

  const handleDeleteAccount = async () => {
    // Prompt the user for their password
    const password = window.prompt('Please enter your password to confirm account deletion:');
    
    // Check if the user entered a password
    if (!password) {
      alert('Password is required to delete the account.');
      return;
    }
  
    try {
      await axiosInstance.post('/users/delete', 
        { password },  // Send the password in the request body
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      );
      handleClose();  // Assuming this closes the modal or confirmation dialog
      signOut();  // Assuming this signs the user out
    } catch (error) {
      console.error('Error deleting account:', error.response?.data || error.message);
      alert('Failed to delete account. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleResendVerificationEmail = async () => {
    try {
      const response = await axiosInstance.post('/users/resend-verification-email', { email: user.email });
      setResendMessage(response.data.message);
    } catch (error) {
      console.error('Error resending verification email:', error);
      setResendMessage('Error resending verification email. Please try again.');
    }
  };

  const handleClose = () => {
    setNewPassword('');
    setConfirmPassword('');
    setCurrentPassword('');
    setUpdatePasswordMessage('');
    setPasswordRequirementsError('');
    setActiveTab('password');
    setShowConfirmDelete(false);
    setShowPassword(false);
    setResendMessage('');
    onClose();
  };

  if (!user) {
    return null;
  }

  return (
    <div className="user-area">
      {/* <div className="user-area-tab-container">
        <button
          className={`user-area-tab br-none ${activeTab === 'password' ? 'active-tab-modal' : ''}`}
          onClick={() => setActiveTab('password')}
        >
          Update Password
        </button>
      </div> */}

      {activeTab === 'password' && (
        <div className="update-section">
          {!user.is_verified && (
            <div className="warning-message">
              <p>
                Your email address has not been verified. Please check your email or{' '}
                <a href="#" className="resend-link" onClick={handleResendVerificationEmail}>resend verification email</a>.
              </p>
              <p>{resendMessage}</p>
            </div>
          )}
          <div className="password-input-container input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handlePasswordChange}
              onPaste={handlePasswordChange}
              placeholder="New Password"
            />
            <i
              className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon eye-icon-change`}
              onClick={togglePasswordVisibility}
            ></i>
          </div>
          <div className="password-input-container input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onPaste={handleConfirmPasswordChange}
              placeholder="Confirm New Password"
            />
            <i
              className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon eye-icon-change`}
              onClick={togglePasswordVisibility}
            ></i>
          </div>
          <div className="password-input-container input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current Password"
            />
            <i
              className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon eye-icon-change`}
              onClick={togglePasswordVisibility}
            ></i>
          </div>
          <button className="update-button" onClick={handleUpdatePassword}>
            Update Password
          </button>
          <p>{updatePasswordMessage}</p>
          <p>{passwordRequirementsError}</p>
        </div>
      )}

      <button className="white-button mb-button" onClick={handleSignOut}>
        Sign Out
      </button>
      {showConfirmDelete ? (
        <div>
          <button className="redder-button mb-button" onClick={handleDeleteAccount}>
            Confirm Account Deletion
          </button>
          <button className="red-button" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <button className="red-button" onClick={() => setShowConfirmDelete(true)}>
          Delete Account
        </button>
      )}
    </div>
  );
};

export default UserArea;
