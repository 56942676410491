// utils.js

export const timeAgo = (timestamp) => {
    const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);
    let interval = Math.floor(seconds / 60); // Convert to minutes
    if (interval < 30) return `${interval} minute${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 3600); // Convert to hours
    if (interval < 12) return `${interval} hour${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 86400); // Convert to days
    if (interval < 4) return `${interval} day${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / (86400 * 7)); // Convert to weeks
    if (interval < 2) return `${interval} week${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / (86400 * 30.5)); // Approximate months
    if (interval < 6) return `${interval} month${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / (86400 * 365)); // Convert to years
    return `${interval} year${interval > 1 ? 's' : ''} ago`;
};

export const calculateNetScore = (reactions) => {
    if (!reactions) return 0;
    const netScore = Object.values(reactions).reduce((sum, value) => sum + value, 0);
    return Math.max(netScore, 0);
};
