// Ensure date keys exist in the data structure
const ensureDateKeys = (data, categories, year) => {
    categories.forEach(category => {
        if (!data[category]) {
            data[category] = {};
        }
        if (!data[category][year]) {
            data[category][year] = 0;
        }
    });
};

// Calculate sum based on available categories
const calculateSum = (data, categories) => {
    return categories.reduce((sum, category) => {
        const value = data[category];
        return sum + (value !== undefined && typeof value === 'number' ? value : 0);
    }, 0);
};

const handleSpecialCategory = (groupedData, category, year) => {
    const componentsMap = {
        'Gross Profit': ['Sales Revenue', 'Other Operating Revenue', '-Direct Costs of Production'],
        'EBITDA': ['Gross Profit', '-Selling, General and Administrative Expenses (SG&A)', '-Research and Development Expenses (R&D)'],
        'EBIT': ['EBITDA', '-Depreciation and Amortization'],
        'Income Before Tax': ['EBIT', 'Interest Income', '-Interest Expense', 'Gain/Loss on Sale of Assets', 'Other Non-Operating Income/Expenses'],
        'Net Income': ['Income Before Tax', '-Income Tax Expense'],
        'Cash from Operating Activities': ['Net Income', 'Adjustments for Non-Cash Items', 'Changes in Working Capital', 'Other Operating Activities'],
        'Cash from Investing Activities': ['Purchase of Property, Plant, and Equipment (Capital Expenditures)', 'Proceeds from Sale of Assets', 'Investments in Securities', 'Proceeds from Sale/Maturity of Investments'],
        'Cash from Financing Activities': ['Proceeds from Issuance of Equity or Debt', 'Repayment of Debt', 'Payment of Dividends', 'Repurchase of Stock'],
        'Net Increase (Decrease) in Cash and Cash Equivalents': ['Cash from Operating Activities', 'Cash from Investing Activities', 'Cash from Financing Activities'],
        'Total Current Assets': ['Current Assets: Cash and Cash Equivalents', 'Current Assets: Accounts Receivable', 'Current Assets: Inventory', 'Current Assets: Prepaid Expenses', 'Current Assets: Other Current Assets'],
        'Total Non-Current Assets': ['Non-Current Assets: Property, Plant, and Equipment (PPE)', 'Non-Current Assets: Inventory', 'Non-Current Assets: Intangible Assets', 'Non-Current Assets: Long-term Investments', 'Non-Current Assets: Accounts Receivable', 'Non-Current Assets: Other Non-Current Assets'],
        'Total Assets': ['Total Current Assets', 'Total Non-Current Assets'],
        'Total Current Liabilities': ['Current Liabilities: Accounts Payable', 'Current Liabilities: Short-term Debt', 'Current Liabilities: Accrued Liabilities', 'Current Liabilities: Other Current Liabilities', 'Current Liabilities: Deferred Tax Liabilities'],
        'Total Non-Current Liabilities': ['Non-current Liabilities: Accounts Payable', 'Non-Current Liabilities: Long-term Debt', 'Non-Current Liabilities: Deferred Tax Liabilities', 'Non-Current Liabilities: Other Non-Current Liabilities'],
        'Total Liabilities': ['Total Current Liabilities', 'Total Non-Current Liabilities'],
        'Total Equity': ['Common Stock', 'Retained Earnings', 'Additional Paid-In Capital', 'Other Comprehensive Income', 'Treasury Stock (if applicable)'],
        'Total Liabilities and Equity': ['Total Liabilities', 'Total Equity'],
        'Cash and Cash Equivalents at End of Period': ['Cash and Cash Equivalents at Beginning of Period', 'Net Increase (Decrease) in Cash and Cash Equivalents']
    };

    if (category === 'Cash and Cash Equivalents at Beginning of Period') {
        const previousYear = new Date(year);
        previousYear.setFullYear(previousYear.getFullYear() - 1);

        // Adjust format to match the groupedData keys
        const prevYearFormatted = previousYear.toISOString().split('T')[0];

        // Find exact match date for previous year
        const prevYearDate = Object.keys(groupedData['Current Assets: Cash and Cash Equivalents'] || {}).find(date => date.startsWith(prevYearFormatted.split('-')[0]));

        let value = groupedData['Current Assets: Cash and Cash Equivalents'] && groupedData['Current Assets: Cash and Cash Equivalents'][prevYearDate]
            ? groupedData['Current Assets: Cash and Cash Equivalents'][prevYearDate]
            : 0;

        if (value === 0 || value === null) {
            const previousPeriodDate = Object.keys(groupedData['Cash and Cash Equivalents at End of Period'] || {}).find(date => date.startsWith(prevYearFormatted.split('-')[0]));
            value = groupedData['Cash and Cash Equivalents at End of Period'] && groupedData['Cash and Cash Equivalents at End of Period'][previousPeriodDate]
                ? groupedData['Cash and Cash Equivalents at End of Period'][previousPeriodDate]
                : 0;
        }

        return value;
    }

    if (componentsMap[category]) {
        const components = componentsMap[category];
        const total = components.reduce((sum, comp) => {
            const isNegative = comp.startsWith('-');
            const component = isNegative ? comp.slice(1) : comp;
            const value = groupedData[component] && groupedData[component][year] ? groupedData[component][year] : 0;
            return sum + (isNegative ? -value : value);
        }, 0);
        return total;
    } else {
        return 99999;
    }
};

// Render Profit & Loss Statement
const renderProfitLossStatement = (groupedData, recentYears, displayUnit, decimalPlaces) => {
    if (!recentYears || recentYears.length === 0) return null;

    const categoriesOrder = [
        'Sales Revenue',
        'Other Operating Revenue',
        'Direct Costs of Production',
        'Gross Profit',
        'Selling, General and Administrative Expenses (SG&A)',
        'Research and Development Expenses (R&D)',
        'EBITDA',
        'Depreciation and Amortization',
        'EBIT',
        'Interest Income',
        'Interest Expense',
        'Gain/Loss on Sale of Assets',
        'Other Non-Operating Income/Expenses',
        'Income Before Tax',
        'Income Tax Expense',
        'Net Income'
    ];

    const specialCategories = new Set([
        'Gross Profit', 'EBITDA', 'EBIT', 'Net Income', 'Total Current Assets',
        'Total Non-Current Assets', 'Total Assets', 'Total Current Liabilities',
        'Total Non-Current Liabilities', 'Total Liabilities', 'Total Equity',
        'Total Liabilities and Equity', 'Cash from Operating Activities', 
        'Cash from Investing Activities', 'Cash from Financing Activities', 
        'Net Increase (Decrease) in Cash and Cash Equivalents', 
        'Cash and Cash Equivalents at Beginning of Period', 
        'Cash and Cash Equivalents at End of Period',
        'Income Before Tax'
    ]);

    recentYears.forEach(year => {
        ensureDateKeys(groupedData, categoriesOrder, year);
        
        categoriesOrder.forEach(category => {
            if (specialCategories.has(category)) {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = handleSpecialCategory(groupedData, category, year);
            } else {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = groupedData[category][year] || 0;
            }
        });
    });

    return categoriesOrder.map((category, index) => (
        <tr 
            key={index} 
            style={{ 
                fontWeight: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'bold' : 'normal',
                backgroundColor: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? '#092056' : 'inherit',
                color: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'white' : 'inherit'
            }}
        >
            <td>{category}</td>
            {recentYears.map((year, index) => (
                <td key={index}>
                    {groupedData[category] && groupedData[category][year] !== undefined ? (groupedData[category][year] / displayUnit).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }) : 'N/A'}
                </td>
            ))}
        </tr>
    ));
};

// Render Cash Flow Statement
const renderCashFlowStatement = (groupedData, recentYears, displayUnit, decimalPlaces) => {
    if (!recentYears || recentYears.length === 0) return null;

    const categoriesOrder = [
        'Net Income',
        'Adjustments for Non-Cash Items',
        'Changes in Working Capital',
        'Other Operating Activities',
        'Cash from Operating Activities',
        'Purchase of Property, Plant, and Equipment (Capital Expenditures)',
        'Proceeds from Sale of Assets',
        'Investments in Securities',
        'Proceeds from Sale/Maturity of Investments',
        'Cash from Investing Activities',
        'Proceeds from Issuance of Equity or Debt',
        'Repayment of Debt',
        'Payment of Dividends',
        'Repurchase of Stock',
        'Cash from Financing Activities',
        'Net Increase (Decrease) in Cash and Cash Equivalents',
        'Cash and Cash Equivalents at Beginning of Period',
        'Cash and Cash Equivalents at End of Period'
    ];

    const specialCategories = new Set([
        'Gross Profit', 'EBITDA', 'EBIT', 'Net Income', 'Total Current Assets',
        'Total Non-Current Assets', 'Total Assets', 'Total Current Liabilities',
        'Total Non-Current Liabilities', 'Total Liabilities', 'Total Equity',
        'Total Liabilities and Equity', 'Cash from Operating Activities', 
        'Cash from Investing Activities', 'Cash from Financing Activities', 
        'Net Increase (Decrease) in Cash and Cash Equivalents', 
        'Cash and Cash Equivalents at Beginning of Period', 
        'Cash and Cash Equivalents at End of Period',
        'Income Before Tax'
    ]);

    recentYears.forEach(year => {
        ensureDateKeys(groupedData, categoriesOrder, year);
        
        categoriesOrder.forEach(category => {
            if (specialCategories.has(category)) {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = handleSpecialCategory(groupedData, category, year);
            } else {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = groupedData[category][year] || 0;
            }
        });
    });

    return categoriesOrder.map((category, index) => (
        <tr 
            key={index} 
            style={{ 
                fontWeight: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'bold' : 'normal',
                backgroundColor: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? '#092056' : 'inherit',
                color: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'white' : 'inherit'
            }}
        >
            <td>{category}</td>
            {recentYears.map((year, index) => (
                <td key={index}>
                    {groupedData[category] && groupedData[category][year] !== undefined ? (groupedData[category][year] / displayUnit).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }) : 'N/A'}
                </td>
            ))}
        </tr>
    ));
};

// Render Balance Sheet
const renderBalanceSheet = (groupedData, recentYears, displayUnit, decimalPlaces) => {
    if (!recentYears || recentYears.length === 0) return null;

    const categoriesOrder = [
        'Current Assets: Cash and Cash Equivalents',
        'Current Assets: Accounts Receivable',
        'Current Assets: Inventory',
        'Current Assets: Prepaid Expenses',
        'Current Assets: Other Current Assets',
        'Total Current Assets',
        'Non-Current Assets: Property, Plant, and Equipment (PPE)',
        'Non-Current Assets: Intangible Assets',
        'Non-Current Assets: Long-term Investments',
        'Non-Current Assets: Other Non-Current Assets',
        'Total Non-Current Assets',
        'Total Assets',
        'Current Liabilities: Accounts Payable',
        'Current Liabilities: Short-term Debt',
        'Current Liabilities: Accrued Liabilities',
        'Current Liabilities: Other Current Liabilities',
        'Total Current Liabilities',
        'Non-Current Liabilities: Long-term Debt',
        'Non-Current Liabilities: Deferred Tax Liabilities',
        'Non-Current Liabilities: Other Non-Current Liabilities',
        'Total Non-Current Liabilities',
        'Total Liabilities',
        'Common Stock',
        'Retained Earnings',
        'Additional Paid-In Capital',
        'Other Comprehensive Income',
        'Treasury Stock (if applicable)',
        'Total Equity',
        'Total Liabilities and Equity'
    ];

    const specialCategories = new Set([
        'Gross Profit', 'EBITDA', 'EBIT', 'Net Income', 'Total Current Assets',
        'Total Non-Current Assets', 'Total Assets', 'Total Current Liabilities',
        'Total Non-Current Liabilities', 'Total Liabilities', 'Total Equity',
        'Total Liabilities and Equity', 'Cash from Operating Activities', 
        'Cash from Investing Activities', 'Cash from Financing Activities', 
        'Net Increase (Decrease) in Cash and Cash Equivalents', 
        'Cash and Cash Equivalents at Beginning of Period', 
        'Cash and Cash Equivalents at End of Period',
        'Income Before Tax'
    ]);

    recentYears.forEach(year => {
        ensureDateKeys(groupedData, categoriesOrder, year);
        
        categoriesOrder.forEach(category => {
            if (specialCategories.has(category)) {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = handleSpecialCategory(groupedData, category, year);
            } else {
                groupedData[category] = groupedData[category] || {};
                groupedData[category][year] = groupedData[category][year] || 0;
            }
        });
    });

    return categoriesOrder.map((category, index) => (
        <tr 
            key={index} 
            style={{ 
                fontWeight: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'bold' : 'normal',
                backgroundColor: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? '#092056' : 'inherit',
                color: [
                    'Gross Profit', 'EBITDA', 'Income Before Tax', 'EBIT', 'Net Income', 
                    'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 
                    'Total Current Liabilities', 'Total Non-Current Liabilities', 
                    'Total Liabilities', 'Total Equity', 'Total Liabilities and Equity', 
                    'Cash from Operating Activities', 'Cash from Investing Activities', 
                    'Cash from Financing Activities', 'Net Increase (Decrease) in Cash and Cash Equivalents', 
                    'Cash and Cash Equivalents at End of Period'
                ].includes(category) ? 'white' : 'inherit'
            }}
        >
            <td>{category}</td>
            {recentYears.map((year, index) => (
                <td key={index}>
                    {groupedData[category] && groupedData[category][year] !== undefined ? (groupedData[category][year] / displayUnit).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }) : 'N/A'}
                </td>
            ))}
        </tr>
    ));
};

const capitalizeWords = (str) => {
    return str ? str.replace(/\b\w/g, char => char.toUpperCase()) : '';
};

export { capitalizeWords, renderProfitLossStatement, renderCashFlowStatement, renderBalanceSheet };
