// src/SECCIKDataPackage.js
import axiosInstance from '../../config/axiosConfig';

export const fetchCompanyData = async (cik, user_id, setCombinedData, setSelectedAccount) => {
    try {
        const response = await axiosInstance.get('/sec/combined-data', {
            params: { cik: cik, user_id: user_id }
        });
        setCombinedData(response.data);
        // console.log(`combined data: ${JSON.stringify(response.data, null, 2)}`);
        if (response.data.length > 0) {
            setSelectedAccount(response.data[0].label);
        }
    } catch (error) {
        console.error('Error fetching company data:', error);
    }
};

export const fetchFilteredLabelDescription = async (cik, setFilteredLabelDescription) => {
    try {
        const response = await axiosInstance.get('/sec/filtered-label-description', {
            params: { cik: cik }
        });
        // console.log('Fetched filtered label descriptions:', response.data); // Log the fetched data
        setFilteredLabelDescription(response.data);
    } catch (error) {
        console.error('Error fetching label descriptions:', error);
    }
};

export const fetchCoFrames = async (cik, setCoFrames) => {
    try {
        const response = await axiosInstance.get(`/sec/co-frames?cik=${cik}`);
        setCoFrames(response.data);
    } catch (error) {
        console.error('Error fetching co-frames data:', error);
    }
}; 

// Fetch model COA data
export const fetchModelCOA = async () => {
    try {
        const response = await axiosInstance.get('/sec/model-coa');
        const data = response.data;

        // console.log('Fetched COA data:', data);
        return data;  // Return the data as is
    } catch (error) {
        console.error('Error fetching model COA data:', error);
        throw error;
    }
};

// export const fetchCoDataMap = async (cik, user_id, setCoDataMap) => {
//     try {
//         console.log(`user_id ${user_id}`)
//         const response = await axiosInstance.get('/sec/co-data-map', {
//             params: { cik: cik, user_id: user_id }
//         });
//         setCoDataMap(response.data);
//     } catch (error) {
//         console.error('Error fetching co-data-map data:', error);
//     }
// };


