import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import '../dist/styles/companyComparisonModal.min.css';
import 'chartjs-adapter-date-fns';
import { graphColors } from './sidepanel/financialViewsHelpersJSONs';
import axiosInstance from '../config/axiosConfig';
import img1 from '../assets/greyLogo1x.png';

const ComparisonModal = ({ isOpen, onClose, initialCompanyData }) => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [unit, setUnit] = useState('millions');
    const [availableUnits, setAvailableUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('USD');
    const [amount, setAmount] = useState('millions');
    const [rawData, setRawData] = useState([]);  // State to hold raw data
    const chartRef = useRef(null);
    const [availablePeriods, setAvailablePeriods] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [isPeriodsOpen, setIsPeriodsOpen] = useState(false);
    const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
    const [isAmountOpen, setIsAmountOpen] = useState(false);
    const amountOptions = ['units', 'thousands', 'millions', 'billions'];
    const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
    const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    useEffect(() => {
        fetchCompanies();
    }, []);
    
    useEffect(() => {
        if (isOpen && initialCompanyData) {
            setSelectedCompanies([initialCompanyData]);
        }
    }, [isOpen, initialCompanyData]);
    
    useEffect(() => {
        const fetchData = async () => {
            if (selectedCompanies.length > 0) {
                console.log('Fetching comparison data for:', selectedCompanies);
                await fetchComparisonDataAndUpdateChart(selectedCompanies);
            }
        };
    
        if (isOpen) {
            fetchData();
        }
    }, [isOpen, selectedCompanies, selectedUnit]);
    
    useEffect(() => {
        const updateChartIfReady = () => {
            if (selectedCompanies.length > 0 && selectedCategories.length > 0 && selectedPeriods.length > 0 && rawData.length > 0) {
                console.log('Updating chart with data:', rawData);
                const chartData = processAndStructureData(rawData, selectedUnit, selectedCategories, amount, selectedPeriods);
                updateChart(chartData);
            }
        };
    
        updateChartIfReady();
    }, [selectedCompanies, selectedCategories, selectedPeriods, rawData, selectedUnit, amount]);
    
    useEffect(() => {
        if (availablePeriods.length > 0 && selectedPeriods.length === 0) {
            setSelectedPeriods([availablePeriods[0]]);
        }
    }, [availablePeriods]);
    

    const fetchCompanies = async () => {
        try {
            const response = await axiosInstance.get('/mappings/companies');
            const companiesData = response.data;
    
            // Sort companies alphabetically by company_name
            const uniqueCompanies = companiesData
                .filter((company, index, self) => index === self.findIndex((comp) => comp.cik === company.cik))
                .sort((a, b) => a.company_name.localeCompare(b.company_name));
    
            setCompanies(uniqueCompanies);
        } catch (error) {
            console.error('Failed to fetch companies:', error);
        }
    };
    

    const fetchComparisonDataAndUpdateChart = async (cikArray) => {
        console.log('fetchComparisonData called with:', cikArray);
        try {
            const response = await axiosInstance.get(`/sec/compare-data/multiple?ciks=${cikArray.join(',')}`);
            const fetchedData = response.data;
            setRawData(fetchedData);  // Store raw data in state

            // Extract available periods and sort them
            const periods = [...new Set(fetchedData.map(item => item.end))].sort((a, b) => new Date(b) - new Date(a));
            setAvailablePeriods(periods);
            if (selectedPeriods.length === 0) {
                setSelectedPeriods(periods); // Select all periods by default
            }

            // Extract available units from the data
            const units = [...new Set(fetchedData.map(item => item.unit))];
            setAvailableUnits(units);

            const chartData = processAndStructureData(fetchedData, selectedUnit, selectedCategories, amount, selectedPeriods);
            updateChart(chartData);

            // Initialize available categories and set the first category as selected by default
            const allCategories = [...new Set(fetchedData.map(item => item.detailed_category))];
            setAvailableCategories(allCategories);
            if (allCategories.length > 0 && selectedCategories.length === 0) {
                setSelectedCategories([allCategories[0]]);
            }

        } catch (error) {
            console.error('Failed to fetch comparison data:', error);
        }
    };

    const processAndStructureData = (data, selectedUnit, selectedCategories, amount, selectedPeriods) => {
        // Group data by company and category, then structure for Chart.js
        const unitFactors = {
            units: 1,
            thousands: 1e3,
            millions: 1e6,
            billions: 1e9,
        };

        const scalingFactor = unitFactors[amount] || 1e6; // Default to millions

        // Filter data by selected periods
        const filteredData = data.filter(item => selectedPeriods.includes(item.end));

        const groupedByCompany = filteredData.reduce((acc, item) => {
            const { company_name, detailed_category, value, unit } = item;

            if (unit !== selectedUnit) return acc;

            if (!acc[company_name]) {
                acc[company_name] = {};
            }

            if (!acc[company_name][detailed_category]) {
                acc[company_name][detailed_category] = 0;
            }

            acc[company_name][detailed_category] += value;

            return acc;
        }, {});

        const allCompanies = Object.keys(groupedByCompany);
        const datasets = selectedCategories.map((category, categoryIndex) => {
            const data = allCompanies.map(company => {
                const financials = groupedByCompany[company];
                return (financials && financials[category] ? financials[category] : 0) / scalingFactor;
            });

            console.log(`Data for Category ${category}:`, data);

            return {
                label: `${category} (${selectedUnit})`,
                data: data,
                backgroundColor: graphColors[categoryIndex % graphColors.length],
                stack: 'Stack 0',
            };
        });

        return {
            labels: allCompanies,
            datasets: datasets,
        };
    };

    const updateChart = (chartData) => {
        if (!chartRef.current) return;

        const ctx = chartRef.current.getContext('2d');

        if (window.myChart) {
            window.myChart.destroy();
        }

        window.myChart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const label = context.dataset.label || '';
                                const value = context.raw || 0;
                                return `${label}: ${value}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: `Value (${selectedUnit})`,
                        },
                    },
                },
            },
        });
    };

    const handleCompanySelection = (e) => {
        const cik = e.target.value;
    
        // Check if the company is already selected
        if (selectedCompanies.includes(cik)) {
            // If the company is already selected, remove it
            const newSelectedCompanies = selectedCompanies.filter(company => company !== cik);
            setSelectedCompanies(newSelectedCompanies);
        } else {
            // Check if the maximum number of selected companies has been reached
            if (selectedCompanies.length >= 6) {
                alert('6 companies max, please remove one before adding another.');
                return;  // Exit the function without adding the new company
            }
    
            // Add the selected company if it's not already in the list
            setSelectedCompanies([...selectedCompanies, cik]);
        }
    };
    

    const handleCategorySelection = (e) => {
        const category = e.target.value;
        if (category && !selectedCategories.includes(category)) {
            // Create the new selected categories array
            const newCategories = [...selectedCategories, category];
            // Update the state
            setSelectedCategories(newCategories);
            // Use the new categories array directly in processAndStructureData
            const chartData = processAndStructureData(rawData, selectedUnit, newCategories, amount, selectedPeriods);
            updateChart(chartData);
        }
    };
    

    const handleCategoryRemoval = (category) => {
        // Create the new selected categories array
        const newCategories = selectedCategories.filter(cat => cat !== category);
        // Update the state
        setSelectedCategories(newCategories);
        // Use the new categories array directly in processAndStructureData
        const chartData = processAndStructureData(rawData, selectedUnit, newCategories, amount, selectedPeriods);
        updateChart(chartData);
    };
    

    const handleUnitChange = (e) => {
        setSelectedUnit(e.target.value);
    };

    const handlePeriodSelection = (e) => {
        setSelectedPeriods(Array.from(e.target.selectedOptions, option => option.value));
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
        const chartData = processAndStructureData(rawData, selectedUnit, selectedCategories, e.target.value, selectedPeriods);
        updateChart(chartData);
    };

    // New function added in from Claude

    const togglePeriod = (period) => {
        setSelectedPeriods(prevPeriods => 
            prevPeriods.includes(period)
                ? prevPeriods.filter(p => p !== period)
                : [...prevPeriods, period]
        );
    };

    // End of new claude function

    return (
        <div className={`modal-comp ${isOpen ? 'open' : ''}`}>
            <div className="modal-comp-content">
                <div className='modal-comp-header'>
                    <div className='modal-comp-h1'>
                        <div className='flex'>
                            <div><img src={img1} alt="Logo" className='logo-image ' /></div>
                            <h2 className='no-margin modal-header'>Compare Financials</h2>
                        </div>
                        <div>
                            <i className="fas fa-times modal-comp-close-icon" onClick={onClose}></i>
                        </div>
                    </div> 
                </div>
                <div className='modal-comp-scrollable-content'>
    
                <div className="modal-comp-chart-container">
                    <canvas ref={chartRef}></canvas>
                </div>
                <div className="filter-section">
                        <div 
                            className="filter-toggle flex"
                            onClick={() => setIsFilterVisible(!isFilterVisible)}
                        >
                            <i className="fas fa-filter"></i>
                            <h2 className=''><span style={{margin: '0 10px'}}>Filters</span></h2>
                            <i className={`fas fa-chevron-${isFilterVisible ? 'up' : 'down'}`}></i>
                        </div>
                        {isFilterVisible && (
                <div className="units-select-container">
                    <div className="units-select-sub-container styled-select-wrapper">
                        <div className='units-sub-header'>

                            <div className="custom-select-container">
                                <div 
                                    className="custom-select-header" 
                                    onClick={() => setIsCurrencyOpen(!isCurrencyOpen)}
                                >
                                    <span>
                                        Currency: {selectedUnit ? selectedUnit : 'Select'}
                                    </span>
                                    <i className={`fas fa-chevron-${isCurrencyOpen ? 'up' : 'down'}`}></i>
                                </div>
                            {isCurrencyOpen && (
                                <div className="custom-select-options">
                                    {availableUnits.map((unit, index) => (
                                        <div
                                            key={index}
                                            className={`custom-select-option ${selectedUnit === unit ? 'selected' : ''}`}
                                            onClick={() => {
                                                handleUnitChange({ target: { value: unit } });
                                                setIsCurrencyOpen(false);
                                            }}
                                        >
                                            {unit}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="custom-select-container" style={{ marginLeft: '15px' }}>
                            <div 
                                className="custom-select-header" 
                                onClick={() => setIsAmountOpen(!isAmountOpen)}
                            >
                                <span>Figure: {amount}</span>
                                <i className={`fas fa-chevron-${isAmountOpen ? 'up' : 'down'}`}></i>
                            </div>
                            {isAmountOpen && (
                                <div className="custom-select-options">
                                    {amountOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className={`custom-select-option ${amount === option ? 'selected' : ''}`}
                                            onClick={() => {
                                                handleAmountChange({ target: { value: option } });
                                                setIsAmountOpen(false);
                                            }}
                                        >
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                            <div className='co-cat-mgmt-sub-header'>
                            <div className="custom-multi-select-container">
                                <div 
                                    className="custom-multi-select-header" 
                                    onClick={() => setIsPeriodsOpen(!isPeriodsOpen)}
                                >
                                    <span>Years Selected: ({selectedPeriods.length})</span>
                                    <i className={`fas fa-chevron-${isPeriodsOpen ? 'up' : 'down'}`}></i>
                                </div>
                                {isPeriodsOpen && (
                                    <div className="custom-multi-select-options">
                                        {availablePeriods.map((period, index) => (
                                            <div
                                                key={index}
                                                className={`custom-multi-select-option ${selectedPeriods.includes(period) ? 'selected' : ''}`}
                                                onClick={() => togglePeriod(period)}
                                            >
                                                {period}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                    </div>
    
                <div className='data-selector-container'>
                    <div className="styled-select-wrapper">
                        <h2 className='co-cat-mgmt-header'>Select Categories</h2>
                        <div className="custom-select-container">
    <div 
        className="custom-select-header" 
        onClick={() => setIsCategoriesOpen(!isCategoriesOpen)}
    >
        <span>Add a Category</span>
        <i className={`fas fa-chevron-${isCategoriesOpen ? 'up' : 'down'}`}></i>
    </div>
    {isCategoriesOpen && (
        <div className="custom-select-options">
            {availableCategories.map((category, index) => (
                <div
                    key={index}
                    className="custom-select-option"
                    onClick={() => {
                        handleCategorySelection({ target: { value: category } });
                        setIsCategoriesOpen(false);
                    }}
                >
                    {category}
                </div>
            ))}
        </div>
    )}
</div>
                    </div>
                    <div className='co-cat-mgmt-sub-header'>
                        <div className="selected-categories-list">
                            {selectedCategories.map((category, index) => (
                                <div key={index} className="selected-category-item">
                                    <i className="fas fa-times remove-category-icon" onClick={() => handleCategoryRemoval(category)}></i>
                                    <span>{category}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="select-container">
                        <div className="styled-select-wrapper">
                            <h2 className='co-cat-mgmt-header'> Select Companies</h2>
                            <div className="custom-select-container">
                                <div 
                                    className="custom-select-header" 
                                    onClick={() => setIsCompaniesOpen(!isCompaniesOpen)}
                                >
                                    <span>Select a Company</span>
                                    <i className={`fas fa-chevron-${isCompaniesOpen ? 'up' : 'down'}`}></i>
                                </div>
                                {isCompaniesOpen && (
                                    <div className="custom-select-options">
                                        {companies.map((company) => (
                                            <div
                                                key={company.cik}
                                                className="custom-select-option"
                                                onClick={() => {
                                                    handleCompanySelection({ target: { value: company.cik } });
                                                    setIsCompaniesOpen(false);
                                                }}
                                            >
                                                {company.company_name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='co-cat-mgmt-sub-header'>
                        <div className="modal-comp-company-list selected-companies-list">
                            {selectedCompanies.map((cik) => (
                                <div key={cik} className="modal-comp-company-item selected-company-item">
                                    <i className="fas fa-times modal-comp-close-company" onClick={() => setSelectedCompanies(selectedCompanies.filter((sc) => sc !== cik))}></i>
                                    <span>{companies.find((comp) => comp.cik === cik)?.company_name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='data-explanation'> Please note this is still in an exploratory phase and so not all companies will have data available here.</div>
                <div className='data-explanation'> Summarised account mappings are subject to community review and so may not be correctly mapped.</div>
                <div className='data-explanation'> To update mappings, go to the company's data section and its community mapping. </div>
                </div>
            </div>
            <div className="site-overlay" onClick={onClose}></div>
        </div>
    );
};

export default ComparisonModal;
