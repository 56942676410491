import React from 'react';
import { format } from 'date-fns';
import { renderProfitLossStatement, renderCashFlowStatement, renderBalanceSheet } from './financialViewsHelpers';
import { currencySymbols } from './financialViewsHelpersJSONs';

const FinancialStatementsView = ({
    combinedData,
    financialStatementTab,
    setFinancialStatementTab,
    financialStatementCategories,
    selectedUnit,
    startYear,
    endYear,
    displayUnit,
    decimalPlaces,
    constituent,
    setViewMethod 
}) => {
    const renderFinancialStatement = () => {
        const statementCategory = financialStatementCategories[financialStatementTab];
        
        // Filter the data for the current financial statement category, unit, and date range
        const financialData = combinedData.filter(data => 
            data.unit.toLowerCase() === selectedUnit.toLowerCase() &&
            data.end >= startYear &&
            data.end <= endYear
        );

        // Determine the recent years to include in the report
        const recentYears = [...new Set(financialData.map(data => data.end))]
            .sort((a, b) => new Date(b) - new Date(a))
            .slice(0, 3);

        // Filter the data to include only those for the recent years
        const filteredData = financialData.filter(data => recentYears.includes(data.end));

        // Group the data by category and year
        const groupedData = filteredData.reduce((acc, curr) => {
            const category = curr.detailed_category;
            const year = curr.end;
            if (!acc[category]) {
                acc[category] = {};
            }
            if (!acc[category][year]) {
                acc[category][year] = 0;
            }
            acc[category][year] += curr.value;
            return acc;
        }, {});

        const getDisplayUnitSuffix = (displayUnit) => {
            switch (displayUnit) {
                case 1000: return "'k";
                case 1000000: return "'m";
                case 1000000000: return "'bn";
                default: return '';
            }
        };        

        const currencySymbol = currencySymbols[selectedUnit.toLowerCase()] || '';
        const displayUnitSuffix = getDisplayUnitSuffix(displayUnit);

        return (
            <>  
                <table className='financial-statement'>
                    <thead>
                        <tr>
                            <th>Category {currencySymbol}{displayUnitSuffix}</th>
                            {recentYears.map((year, index) => (
                                <th key={index}>{format(new Date(year), 'MMM-yy')}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {financialStatementTab === 'profitLoss' && renderProfitLossStatement(groupedData, recentYears, displayUnit, decimalPlaces)}
                        {financialStatementTab === 'cashFlow' && renderCashFlowStatement(groupedData, recentYears, displayUnit, decimalPlaces)}
                        {financialStatementTab === 'balanceSheet' && renderBalanceSheet(groupedData, recentYears, displayUnit, decimalPlaces)}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <>
            <h3 className='data-header'> View {constituent.company_name}'s Summarised Financial Statements </h3>
            <div className="financial-statement-tabs">
                <span
                    className={`pill ${financialStatementTab === 'profitLoss' ? 'active' : ''}`}
                    onClick={() => setFinancialStatementTab('profitLoss')}
                >
                    Profit / Loss
                </span>
                <span
                    className={`pill ${financialStatementTab === 'balanceSheet' ? 'active' : ''}`}
                    onClick={() => setFinancialStatementTab('balanceSheet')}
                >
                    Balance Sheet
                </span>
                <span
                    className={`pill ${financialStatementTab === 'cashFlow' ? 'active' : ''}`}
                    onClick={() => setFinancialStatementTab('cashFlow')}
                >
                    Cash Flow Statement
                </span>
            </div>
            {selectedUnit && (
                <div className="financial-statement selected-unit">
                    {renderFinancialStatement()}
                </div>
            )}
            <div className='data-explanation mob-hide'> Summarised account mappings are subject to community review and so may not be correctly mapped.</div>
            <div className='data-explanation mb-20'>
                To update
                <div className='mob-show' style={{ display: 'none' }}>
                    &nbsp; mappings
                </div>
                , use <u><b><i onClick={() => setViewMethod('communityData')} style={{ cursor: 'pointer' }}>Community Data Mapping</i></b></u> tab
            </div>
            <div className='data-disclaimer'>
                Do not use this site for investment decisions.
            </div> 
        </>
    );
};

export default FinancialStatementsView;
