import React, { useState, useContext } from 'react';
import DOMPurify from 'dompurify';
import { timeAgo, calculateNetScore } from './Utils';
import NewCommentSection from './NewCommentSection';
import { AuthContext } from '../membersarea/contexts/AuthContext';

const Comment = ({
    comment, handleLikeClick, handleDislikeClick, handleReplyClick, handleFlagClick,
    handleDeleteClick, user_id, fetchData, replyTo, setReplyTo, commentText,
    setCommentText, editorRef, depth = 0,
    userRating, setUserRating, handlePostComment, companyName
}) => {
    const { user } = useContext(AuthContext);
    const displayScore = calculateNetScore(comment.reactions);
    const [showReplies, setShowReplies] = useState(true); // Set to true initially
    const [verificationMessage, setVerificationMessage] = useState('');

    const hasLiked = comment.reactions && comment.reactions[user_id] === 1;
    const hasDisliked = comment.reactions && comment.reactions[user_id] === -1;
    const hasFlagged = comment.flagged && comment.flagged[user_id];
    const isAuthor = comment.user_id === user_id;

    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    const createMarkup = (htmlContent) => {
        return { __html: DOMPurify.sanitize(htmlContent) };
    };

    const countAllReplies = (comment) => {
        let count = 0;
        const countReplies = (replies) => {
            if (replies && replies.length > 0) {
                count += replies.length;
                replies.forEach(reply => countReplies(reply.replies));
            }
        };
        countReplies(comment.replies);
        return count;
    };

    const allRepliesCount = countAllReplies(comment);

    const handleDeleteConfirm = (commentId) => {
        const confirmed = window.confirm('Are you sure you want to delete this comment?');
        if (confirmed) {
            handleDeleteClick(user, commentId, () => alert('Please sign in to delete comments.'), fetchData);
        }
    };

    const handleReplyClickWrapper = (comment) => {
        if (!user) {
            alert('Please sign in to comment.');
            return;
        }
        if (!user.is_verified) {
            setVerificationMessage('Please verify your email to comment.');
            return;
        }
        setVerificationMessage('');
        handleReplyClick(user, comment, setReplyTo, () => alert('Please sign in to reply to comments.'), editorRef);
    };

    return (
        <>
            <div className={`comment-section ${comment.parent_comment_id ? 'reply-message' : ''}`} style={{ marginLeft: depth * 20 + 'px' }}>
                <div className={`message user ${depth === 0 && showReplies && comment.replies && comment.replies.length > 0 ? 'open-replies' : ''}`} style={{ zIndex: 100 }}>
                    <div className="message-top">
                        <div className="meta-user">{comment.username}</div>
                        <div className="meta-timestamp">{timeAgo(comment.created_at)}</div>
                    </div>
                    <div className="message-content" dangerouslySetInnerHTML={createMarkup(comment.content)}></div>
                    <div className="message-actions">
                        {comment.content !== 'Comment deleted' && (
                            <>
                                <div className="meta-left">
                                    <i
                                        className={`fas fa-arrow-up ${hasLiked ? 'liked' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleLikeClick(user, comment.comment_id, () => alert('Please sign in to like comments.'), fetchData)}
                                    ></i>&nbsp;
                                    <span className="net-score">{displayScore}</span>&nbsp;
                                    <i
                                        className={`fas fa-arrow-down ${hasDisliked ? 'disliked' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleDislikeClick(user, comment.comment_id, () => alert('Please sign in to dislike comments.'), fetchData)}
                                    ></i>
                                </div>
                                <div className="meta-right">
                                    {comment.replies && comment.replies.length > 0 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i
                                                className={`fas ${showReplies ? 'fa-minus' : 'fa-plus'}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={toggleReplies}
                                            ></i>&nbsp;
                                            <span
                                                className="replies-count"
                                                style={{ cursor: 'pointer' }}
                                                onClick={toggleReplies}
                                            >
                                                {allRepliesCount} {allRepliesCount === 1 ? 'reply' : 'replies'}
                                            </span>&nbsp;
                                        </div>
                                    )}
                                    <i
                                        className="fas fa-reply"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleReplyClickWrapper(comment)}
                                    ></i>&nbsp;
                                    <i
                                        className={`fas fa-flag ${hasFlagged ? 'flagged' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleFlagClick(user, comment.comment_id, () => alert('Please sign in to flag comments.'), fetchData)}
                                    ></i>&nbsp;
                                    {isAuthor && (
                                        <i
                                            className="fas fa-trash"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteConfirm(comment.comment_id)}
                                        ></i>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {replyTo && replyTo.comment_id === comment.comment_id && user && user.is_verified && (
                        <NewCommentSection
                            commentText={commentText}
                            setCommentText={setCommentText}
                            handlePostComment={handlePostComment}
                            replyTo={replyTo}
                            setReplyTo={setReplyTo}
                            editorRef={editorRef}
                            parentCommentId={comment.comment_id}
                            userRating={userRating}
                            setUserRating={setUserRating}
                            companyName={companyName} // Pass company name
                        />
                    )}
                </div>
            </div>
            {showReplies && comment.replies && comment.replies.length > 0 && (
                <div className={`replies-section ${depth > 0 ? 'nested-reply' : ''}`}>
                    {comment.replies.map(reply => (
                        <Comment
                            key={reply.comment_id}
                            comment={reply}
                            handleLikeClick={handleLikeClick}
                            handleDislikeClick={handleDislikeClick}
                            handleReplyClick={handleReplyClick}
                            handleFlagClick={handleFlagClick}
                            handleDeleteClick={handleDeleteClick}
                            user_id={user_id}
                            fetchData={fetchData}
                            replyTo={replyTo}
                            setReplyTo={setReplyTo}
                            commentText={commentText}
                            setCommentText={setCommentText}
                            editorRef={editorRef}
                            handlePostComment={handlePostComment} // Pass handlePostComment
                            userRating={userRating}
                            setUserRating={setUserRating}
                            depth={depth + 1}
                            companyName={companyName} // Pass company name
                        />
                    ))}
                </div>
            )}
            {verificationMessage && (
                <div className="verification-message" style={{ color: 'red', marginLeft: depth * 20 + 'px' }}>
                    {verificationMessage}
                </div>
            )}
        </>
    );
};

export default Comment;
