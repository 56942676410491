import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender
} from '@tanstack/react-table';
import { format } from 'date-fns';
import axiosInstance from '../../config/axiosConfig';
import { AuthContext } from '../membersarea/contexts/AuthContext';
import { fetchCompanyData } from './SECCIKDataPackage'; // Import fetchCompanyData

const CommunityDataMapping = ({ constituent, combinedData = [], setCombinedData, setSelectedAccount, modelCOAData }) => {
    const { user } = useContext(AuthContext);
    const [columnOrder, setColumnOrder] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editedRow, setEditedRow] = useState({});
    const [coaData, setCoaData] = useState([]);

    useEffect(() => {
        if (modelCOAData) {
            setCoaData(modelCOAData);
        }
    }, [modelCOAData]);

    const formatDate = (dateString) => format(new Date(dateString), 'MMM-yy');

    useEffect(() => {
        if (combinedData && Array.isArray(combinedData)) {
            combinedData.forEach((item, index) => {
                if (
                    !item.label || typeof item.label !== 'string' ||
                    !item.detailed_category || typeof item.detailed_category !== 'string' ||
                    !item.high_level_category || typeof item.high_level_category !== 'string' ||
                    !item.financial_statement || typeof item.financial_statement !== 'string'
                ) {
                    console.error(`Invalid data structure at index ${index}`, item);
                }
            });
        } else {
            console.error('combinedData is not an array:', combinedData);
        }
    }, [combinedData]);

    const getUniqueData = (data) => {
        const uniqueData = [];
        const uniqueSet = new Set();

        data.forEach(item => {
            const key = `${item.label}-${item.financial_statement}-${item.high_level_category}-${item.detailed_category}`;
            if (!uniqueSet.has(key)) {
                uniqueSet.add(key);
                uniqueData.push(item);
            }
        });

        return uniqueData;
    };

    const filteredData = useMemo(() => {
        if (!Array.isArray(combinedData)) {
            console.error('Data is not an array:', combinedData);
            return [];
        }
        const uniqueCombinedData = getUniqueData(combinedData);
        return uniqueCombinedData.filter(item => item.label);
    }, [combinedData]);

    const columns = useMemo(
        () => [
            { accessorKey: 'label', header: 'Original Category', size: 30 },
            { accessorKey: 'financial_statement', header: 'Financial Statement', size: 20 },
            { accessorKey: 'high_level_category', header: 'High Level Category', size: 20 },
            { accessorKey: 'detailed_category', header: 'Detailed Category', size: 30 }
        ],
        []
    );

    const handleEditClick = async (row) => {
        if (!user || !user.user_id) {
            alert('Please sign in to update mappings.');
            return;
        }
        setEditingRowIndex(row.index);
        setEditedRow({ ...row.original });
    };

    const handleSaveClick = async () => {
        if (!user || !user.user_id) {
            alert('Please sign in to update mappings.');
            return;
        }
        const valid = validateHierarchy(editedRow);
        if (valid) {
            const updatedData = [...filteredData];
            updatedData[editingRowIndex] = editedRow;
            setEditingRowIndex(null);
            setEditedRow({});
            await submitUpdatedMapping(editedRow, constituent);
            await fetchCompanyData(constituent.cik, user.user_id, setCombinedData, setSelectedAccount); // Fetch updated data
        } else {
            alert('Invalid hierarchy selected. Please correct.');
        }
    };

    const handleCancelClick = () => {
        setEditingRowIndex(null);
        setEditedRow({});
    };

    const handleAgreeClick = async (row) => {
        if (!user || !user.user_id) {
            alert('Please sign in to update mappings.');
            return;
        }
        // console.log('Agree Click - Row:', row.original);
        await submitUpdatedMapping(row.original, constituent);
        await fetchCompanyData(constituent.cik, user.user_id, setCombinedData, setSelectedAccount); // Fetch updated data
    };

    const validateHierarchy = (row) => {
        return coaData.some(item =>
            item.financialStatements.includes(row.financial_statement) &&
            item.highLevelCategories.includes(row.high_level_category) &&
            item.detailedCategories.includes(row.detailed_category)
        );
    };

    const handleFieldChange = (field, value) => {
        setEditedRow(prev => ({ ...prev, [field]: value }));
        if (field === 'financial_statement') {
            setEditedRow(prev => ({
                ...prev,
                high_level_category: '',
                detailed_category: ''
            }));
        }
        if (field === 'high_level_category') {
            setEditedRow(prev => ({ ...prev, detailed_category: '' }));
        }
    };

    const submitUpdatedMapping = (row, constituent) => {
        if (!user || !user.user_id) {
            alert('Please sign in to update mappings.');
            return;
        }

        // console.log('Submit Updated Mapping - Row:', row);
        // console.log('Submit Updated Mapping - Constituent:', constituent);
        return axiosInstance.post('/mappings/update', {
            label: row.label,
            cik: constituent.cik,
            company: constituent.company_name,
            newMapping: {
                social_detailed_category: row.detailed_category,
                social_high_level_category: row.high_level_category,
                social_financial_statement: row.financial_statement
            },
            user_id: user.user_id  // Use user information from context
        }).then(response => {
            // console.log('Mapping updated successfully');
        }).catch(error => {
            console.error('Error updating mapping:', error);
        });
    };

    const renderCellContent = (cell) => {
        const isEditing = cell.row.index === editingRowIndex;
        const field = cell.column.id;
        const value = cell.getValue();

        if (isEditing) {
            if (field === 'financial_statement') {
                const financialStatements = [...new Set(coaData.flatMap(item => item.financialStatements))];
                return (
                    <select className="full-width-select" value={editedRow.financial_statement || ''} onChange={e => handleFieldChange('financial_statement', e.target.value)}>
                        <option value="">Select</option>
                        {financialStatements.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                );
            }

            if (field === 'high_level_category') {
                const options = [...new Set(coaData
                    .filter(item => item.financialStatements.includes(editedRow.financial_statement))
                    .flatMap(item => item.highLevelCategories))];
                return (
                    <select className="full-width-select" value={editedRow.high_level_category || ''} onChange={e => handleFieldChange('high_level_category', e.target.value)}>
                        <option value="">Select</option>
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                );
            }

            if (field === 'detailed_category') {
                const options = [...new Set(coaData
                    .filter(item => item.highLevelCategories.includes(editedRow.high_level_category))
                    .flatMap(item => item.detailedCategories))];
                return (
                    <div className="detailed-category-cell" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="detailed-category-text" style={{ flex: 1 }}>
                            <select className="full-width-select" value={editedRow.detailed_category || ''} onChange={e => handleFieldChange('detailed_category', e.target.value)}>
                                <option value="">Select</option>
                                {options.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                        <div className="coa-edit-icons" style={{ flexShrink: 0, minWidth: '20px', marginLeft: '8px', display: 'flex' }}>
                            <i className="fas fa-check" onClick={handleSaveClick} style={{ cursor: 'pointer', paddingRight: '10px' }}></i>
                            <i className="fas fa-times" onClick={handleCancelClick} style={{ cursor: 'pointer', paddingRight: '10px' }}></i>
                        </div>
                    </div>
                );
            }
        }

        if (field === 'detailed_category') {
            return (
                <div className="detailed-category-cell" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="detailed-category-text" style={{ flex: 1 }}>
                        {value || 'N/A'}
                    </div>
                    <div className="coa-edit-icons" style={{ flexShrink: 0, minWidth: '20px', marginLeft: '8px', display: 'flex' }}>
                        <i className="fas fa-pencil-alt" onClick={() => handleEditClick(cell.row)} style={{ cursor: 'pointer', paddingRight: '10px' }}></i>
                        <i className="fas fa-check" onClick={() => handleAgreeClick(cell.row)} style={{ cursor: 'pointer', paddingRight: '10px' }}></i>
                    </div>
                </div>
            );
        }

        return value || 'N/A';
    };

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            columnOrder: columnOrder.length ? columnOrder : undefined,
            columnFilters,
        },
        onColumnOrderChange: setColumnOrder,
        onColumnFiltersChange: setColumnFilters,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    });

    return (
        <div>
            <h3 className='comm-map-desc'>
                Our mapping aligns an original category with three standardized ones using group consensus. Help us improve by updating incorrect mappings!
            </h3>
            <div className="coa-table-container">
                <table className="data-table coa-table">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <React.Fragment key={headerGroup.id}>
                                <tr>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id} className="header-cell" style={{ width: `${header.column.columnDef.size}%` }}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <div
                                                        style={{
                                                            paddingRight: '5px',
                                                        }}
                                                    >
                                                        {header.column.getIsSorted()
                                                            ? header.column.getIsSorted() === 'desc'
                                                                ? '▼'
                                                                : '▲'
                                                            : '⬍'}
                                                    </div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id} className="filter-cell">
                                            {header.isPlaceholder ? null : (
                                                <input
                                                    type="text"
                                                    value={header.column.getFilterValue() || ''}
                                                    onChange={e => header.column.setFilterValue(e.target.value)}
                                                    placeholder={`Search ${header.column.columnDef.header}`}
                                                    className="filter-input"
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </React.Fragment>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id} className={row.id % 2 === 0 ? 'even-row' : 'odd-row'}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {renderCellContent(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CommunityDataMapping;
