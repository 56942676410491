import React, { useState } from 'react';
import img1 from '../../assets/greyLogo0.5x.png';
import axiosInstance from '../../config/axiosConfig';
import '../../dist/styles/ResetPassword.min.css';

const ResetPasswordModal = ({ onClose, onToggleSignIn }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // New loading state

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        try {
            // Send reset password request to backend
            const response = await axiosInstance.post('/users/forgot-password', { email });
            setMessage(response.data.message); // Handle success response
            setEmail(''); // Clear email input
            setLoading(false); // Stop loading
        } catch (error) {
            setLoading(false); // Stop loading
            if (error.response && error.response.data) {
                setError(error.response.data.error); // Handle backend error message
            } else {
                setError('Something went wrong. Please try again.'); // Handle generic error
            }
        }
    };

    const handleReturnToSignIn = () => {
        onToggleSignIn(); // Call the prop function to return to sign-in modal
        onClose(); // Close the reset password modal
    };

    return (
        <>
            <div className="site-overlay"></div>
            <div className="modal-overlay" onClick={(e) => handleOverlayClick(e, onClose)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div><img src={img1} alt="Logo" className='logo-image' /></div>
                        <h2>Reset Password</h2>
                        <i className="fas fa-times modal-close-icon" onClick={onClose}></i>
                    </div>
                    <form onSubmit={handleResetPassword}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder="Email *"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> Resetting...
                                </>
                            ) : (
                                'Reset Password'
                            )}
                        </button>
                    </form>
                    {message && <p className="success">{message}</p>}
                    {error && <p className="error">{error}</p>}
                    <div className="modal-footer">
                        <p>
                            Remembered your password?{' '}
                            <span className="modal-link" onClick={onToggleSignIn}>
                                Return to Log In
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordModal;

function handleOverlayClick(e, onClose) {
    if (e.target.classList.contains('site-overlay') || e.target.classList.contains('modal-overlay')) {
        onClose();
    }
}
